/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '../../router'
import store from '../../store'
import * as types from './mutation-types';
import axios from "axios";

export const getDispositionIn = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('disposition_in')) {
        params = JSON.parse(last[1]);
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    let flag_atasan = '0';
    let flag_from = store.state.dispositionIn.from;
    let atasan = store.state.dispositionIn.jabatan;
    let nomor_kendali = store.state.dispositionIn.nomor_kendali;
    let nomor_surat = store.state.dispositionIn.nomor_surat;
    let cari = store.state.dispositionIn.cari;
    let start_date = store.state.dispositionIn.start_date;
    let end_date = store.state.dispositionIn.end_date;
    let atasan1 = '';
    if (atasan > '0' && flag_from == 'pimpinan') {
        atasan1 = 'pimpinan';
    } else if (atasan > '0' && flag_from != 'pimpinan') {
        atasan1 = flag_from;
    } else {
        atasan1 = flag_from;
    }
    axios.get("/disposition_in", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1')),
            limit: limit,
            flag_atasan: flag_atasan,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            subject: store.state.dispositionIn.subject ? store.state.dispositionIn.subject : 'all',
            // jabatan: atasan1,
            from: atasan1,
            // from: atasan,
            from1: store.state.dispositionIn.from,
            cari: cari,
            nomor_kendali: nomor_kendali,
            nomor_surat: nomor_surat,
            start_date: start_date,
            end_date: end_date,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data,
            isLeader: response.data.is_leader
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("document_in", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDispositionInById = ({ commit }, val) => {
    let param = null;
    if (val.id !== undefined) {
        param = val.id;
    } else {
        param = val.dispoid;
    }
    axios.get(`/disposition_in/${param}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
// eslint-disable-next-line no-unused-vars
export const submitEdit = ({ commit }, payload) => {
    axios.post("/disposition_in/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn edit',
        params: {
            id: val.id
        }
    })
};

export const onResponse = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isResponse: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn response'
    })
};
export const submitResponse = ({ commit }, payload) => {
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onTeruskan = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn teruskan',
        params: {
            dispoid: val.id
        }
    })
};

export const onSend = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn send',
        params: {
            id: val.id
        }
    })
};

export const submitTeruskan = ({ commit }, payload) => {
    axios.post("/disposition_in/forward", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'dispositionIn'
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onJawaban = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isJawaban: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn jawaban',
        params: {
            dispoid: val.id
        }
    })
};

export const submitJawaban = ({ commit }, payload) => {
    axios.post("/disposition_response", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onJawabDispo = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isJawabDispo: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn jawabdispo',
        params: {
            dispoid: val.id
        }
    })
};

// eslint-disable-next-line no-unused-vars
export const submitJawabDispo = ({ commit }, payload) => {
    axios.post("/jawabdispo_mail/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };

        store.commit("addNotification", message);
        if (response.data.data.is_false == true) {
            router.push({
                name: 'dispositionIn'
            })
        } else {
            let d = JSON.parse(payload.data)
            if (d.response_dispo == "0") {
                router.push({
                    name: 'dispositionIn'
                })
            } else {
                router.push({
                    name: 'dispositionIn detail',
                    params: {
                        id: payload.id
                    }
                })
            }
        }

    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitSelesaiDispo = ({ commit }, payload) => {
    axios.post("/selesaidispo_mail/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };

        store.commit("addNotification", message);
        if (response.data.data.is_false == true) {
            router.push({
                name: 'dispositionIn'
            })
        } else {
            let d = JSON.parse(payload.data)
            if (d.response_dispo == "0") {
                router.push({
                    name: 'dispositionIn'
                })
            } else {
                router.push({
                    name: 'dispositionIn detail',
                    params: {
                        id: payload.id
                    }
                })
            }
        }
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
}


export const submitDoneDispo = ({ commit }, payload) => {
    axios.post("/disposition_in/donedispo", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.state === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn detail',
        params: {
            id: val.id
        }
    })
};

export const onDetailTL = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn detailTL',
        params: {
            id: val.id
        }
    })
};

export const onDetailTLI = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isEdit: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn detailTLI',
        params: {
            id: val.id
        }
    })
};

export const onCancel = () => {
    router.push({
        name: 'dispositionIn'
    })
};
// eslint-disable-next-line no-unused-vars
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/disposition_in/${val.id}`).then(() => {
        store.dispatch('dispositionIn/getDispositionIn');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const sendDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const saveDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionAlreadySend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitTandaiAtasan = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in_atasan/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Tandai Surat Atasan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'dispositionIn',
            // params: {
            //     id: payload.id
            // }
            // eslint-disable-next-line no-unused-vars
        }).catch(err => {
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitTandaiAtasanDepan = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in_atasan/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Tandai Surat Atasan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'dispositionIn'
        }).catch(err => {
        })
    });
};



export default {
    getDispositionIn,
    getDispositionInById,
    onAdd,
    onEdit,
    onDetail,
    onDetailTL,
    onDetailTLI,
    onResponse,
    onCancel,
    onTeruskan,
    onSend,
    onJawaban,
    onJawabDispo,
    submitAdd,
    submitEdit,
    submitDelete,
    submitResponse,
    submitTeruskan,
    submitJawaban,
    submitJawabDispo,
    submitSelesaiDispo,
    submitDoneDispo,
    submitDisposisi,
    submitTandaiAtasan,
    submitTandaiAtasanDepan,
    sendDisposisi,
    saveDisposisi
};
