<template>
	<div
		class="flex w-full h-screen items-center justify-center text-center"
		style="width: 100%;padding:10px 0px;justify-content: center; align-items: center; display: flex;"
		id="app"
	>
		<div class="p-12 bg-gray-100 border border-gray-300" style="    padding: 3rem;    border-width: 1px;    border-color: #e2e8f0;background-color: #f7fafc;" @dragover="dragover" @dragleave="dragleave" @drop="drop">
			<input
				type="file"
				name="fields[assetsFieldHandle][]"
				id="assetsFieldHandle"
				accept=".p12"
				style="width: 1px;position: absolute;overflow: hidden;opacity: 0;height: 1px;"
				class="w-px h-px opacity-0 overflow-hidden absolute"
				@change="onChange"
				ref="file"
			/>

			<label for="assetsFieldHandle" class="block cursor-pointer" style="display: block; cursor: pointer;">
				<div>
					Letakkan file P12 anda disini atau <span class="underline" style="    text-decoration: underline;">klik di sini</span> untuk mengunggah file
				</div>
			</label>
			<ul class="mt-4" style="margin-top: 1rem;list-style: none;margin: 0;padding: 0;" v-if="this.filelist.length" v-cloak>
				<li class="text-sm p-1" style="padding: .25rem;font-size: .875rem;" v-for="(file, index) in filelist" :key="index">
					{{ file.name
					}}<button class="btn btn-danger ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">
						Hapus
					</button>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			file: '', // Store our base64
			filelist: [] // Store our uploaded files
		};
	},
	methods: {
        createFile(file) {
        var reader = new FileReader();
        var vm = this;

        reader.onload = e => {
            vm.file = e.target.result;
        };
        reader.readAsDataURL(file);
        },
		onChange() {
			this.filelist = [...this.$refs.file.files];
			while (this.filelist.length > 1) {
				this.filelist.pop();
			}
		},
		remove(i) {
			this.filelist.splice(i, 1);
		},
		dragover(event) {
			event.preventDefault();
			// Add some visual fluff to show the user can drop its files
			if (!event.currentTarget.classList.contains('bg-green-300')) {
				event.currentTarget.classList.remove('bg-gray-100');
				event.currentTarget.classList.add('bg-green-300');
			}
		},
		dragleave(event) {
			// Clean up
			event.currentTarget.classList.add('bg-gray-100');
			event.currentTarget.classList.remove('bg-green-300');
		},
		drop(event) {
			event.preventDefault();
			// this.$refs.file.files[0].type
			if(event.dataTransfer.files[0].type == 'application/x-pkcs12'){
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange(); // Trigger the onChange event manually
			}else{
				const message = {
					title: this.$t('error'),
					text: "Pastikan file yang anda masukkan bertipe p12",
					type: "error"
				};
				this.$store.commit("addNotification", message);
			}
			// Clean up
			event.currentTarget.classList.add('bg-gray-100');
			event.currentTarget.classList.remove('bg-green-300');
		}
	},
	watch: {
		filelist(newValue) {
            if(newValue.length){
                this.file = newValue[0]
            }else{
                this.file = ''
            }
        },
        file(newValue){
            this.$emit('data', newValue);
        }
	}
};
</script>
