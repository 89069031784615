<template>
  <div :class="[actualSize, { 'has-danger': hasDanger }]">
    <label>
      {{ label }}
      <small v-if="required" style="color:red">*</small>
    </label>
    <div>
      <treeselect
        :multiple="multiple"
        :options="list"
        :disabled="disabled"
        :limit="50"
        :auto-load-root-options="false"
        :load-options="loadOptions"
        :disable-branch-nodes="disableBranchNode"
        :placeholder="actualPlaceholder"
        value-format="object"
        v-model="vmodel"
      />
      <small v-if="hasDanger" class="form-control-feedback"
        >Data ini harus di isi.</small
      >
    </div>
  </div>
</template>
<script>
import { LOAD_CHILDREN_OPTIONS } from "@riophae/vue-treeselect";

export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
      default: "12",
    },
    placeholder: {
      required: false,
      type: String,
      default: "",
    },
    type: {
      required: false,
      type: String,
      default: "text",
    },
    model: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    disableBranchNode: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: true,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    list: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    actualSize() {
      return `col-md-${this.size}`;
    },
    actualPlaceholder() {
      return this.placeholder == null || this.placeholder == ""
        ? this.label
        : this.placeholder;
    },
    hasDanger() {
      if (Array.isArray(this.vmodel)) {
        return (
          (this.vmodel.length == 0) && (this.vmodel !== 0) &&
          this.required == true
        );
      } else {
        return (
          (this.vmodel == null || this.vmodel == "") &&
          this.vmodel !== 0 &&
          this.required == true
        );
      }
    },
  },
  data() {
    return {
      vmodel: null,
    };
  },
  mounted() {
    this.vmodel = this.model;
  },
  watch: {
    model: function(newValue) {
      this.vmodel = newValue;
    },
    vmodel: function(newValue) {
      this.$emit("data", newValue);
    },
  },
  methods: {
    loadOptions({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        this.$emit("action", {
          parentNode: parentNode,
          callback: callback,
        });
      }
    },
  },
};
</script>
