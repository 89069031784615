<template>
  <li>
    <div :class="{ bold: isFolder }" @click="toggle" @dblclick="changeType">
      <!-- <span v-if="isFolder">
        <i :class="!open ?'mdi mdi-chevron-right':'mdi mdi-chevron-down'"></i>
      </span>-->
      <div
        class="media"
        :style="[
          model.is_read
            ? { 'background-color': 'DodgerBlue' }
            : { 'background-color': 'Tomato' },
        ]"
      >
        <!-- <div class="media" style="background-color: DodgerBlue"> -->
        <img
          class="d-flex mr-3"
          src="@/assets/img/logo.png"
          width="60"
          alt="Generic placeholder image"
        />
        <div>
          <div class="media-body">
            <!-- <h5 class="mt-0 mb-1">{{model.name}}</h5> -->
            <span>
              <strong class="col-md-2">Unit Kerja:</strong>
              {{ model.position }}
            </span>
            <br />
            <!-- <span>
              <strong class="col-md-2">Id Dispo</strong>
              {{ model.id_dispo }}
              <br />
              {{ model.unit }}
              <br />
              {{ model.dataku }}
            </span>
            <br /> -->
            <span>
              <strong class="col-md-2">Jabatans:</strong>
              {{ model.jabatan }}
            </span>
            <!-- <br>
            <span>
              <strong class="col-md-2">Posisi:</strong>
              {{model.position}}
            </span> -->
            <br />
            <span>
              <strong class="col-md-2">Status:</strong>
              {{ model.status }}
            </span>
            <br />
            <!-- <span>
              <strong class="col-md-2">Pemberi Dispo / Profil:</strong>
              {{ model.sender_unit ? model.sender_unit : model.unit }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ model.sender_profile ? model.sender_profile : model.profil }}
            </span> -->
            <span>
              <strong class="col-md-2">Profil : </strong>
              {{ model.profil }}
            </span>
            <br />
            <span>
              <strong class="col-md-2">Catatan:</strong>
              <span v-html="model.description"></span>
            </span>
          </div>
        </div>

        <span v-if="isFolder" class="pull-right">
          <i
            :class="!open ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-down'"
          ></i>
        </span>
      </div>
    </div>
    <ul class="tree-view" v-show="open" v-if="isFolder">
      <li>
        <item
          v-for="(model, index) in model.children"
          :key="index"
          :model="model"
        ></item>
      </li>
    </ul>
  </li>
</template>
<script>
import Vue from "vue";
import item from "./item";
export default {
  components: {
    item,
  },
  name: "item",
  props: {
    model: Object,
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    changeType() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open = true;
      }
    },
  },
};
</script>
<style scoped>
.media {
  min-width: 100%;
  width: fit-content;
}
</style>