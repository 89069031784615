const router = {
    path: 'documentIn',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'documentIn add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_in.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'documentIn edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_in.update']
            },
        }, {
            path: 'konfirmasi/:id',
            name: 'documentIn konfirmasi',
            component: () => import('./containers/konfirmasi'),
            meta: {
                roles: ['document_in_confirm.create']
            },
        }, {
            path: 'detail/:id',
            name: 'documentIn detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['document_in.read']
            },
        }, {
            path: 'detailTL/:id',
            name: 'documentIn detailTL',
            component: () => import('./containers/detailTL'),
            meta: {
                roles: ['document_in.read']
            },
        }, {
            path: 'detailTLI/:id',
            name: 'documentIn detailTLI',
            component: () => import('./containers/detailTLI'),
            meta: {
                roles: ['document_in.read']
            },
        }, {
            path: 'disposisi/:id',
            name: 'documentIn disposisi',
            component: () => import('./containers/disposisi'),
            meta: {
                roles: ['disposition_in.create']
            },
        }, {
            path: 'confirm/:id',
            name: 'documentIn confirm',
            component: () => import('./containers/confirm'),
            meta: {
                roles: ['document_in_confirm.create']
            },
        }, {
            path: 'close/:id',
            name: 'documentIn close',
            component: () => import('./containers/close'),
            meta: {
                roles: ['document_in_confirm.create']
            },
        },
        // {
        //     path: 'retur/:id',
        //     name: 'documentIn retur',
        //     component: () => import('./containers/retur'),
        //     meta: {
        //         roles: ['document_in.read']
        //     },
        // },
        {
            path: 'return/:id',
            name: 'documentIn return',
            component: () => import('./containers/return'),
            meta: {
                roles: ['document_in_confirm.create']
            },
        }, {
            path: '',
            name: 'documentIn',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['document_in.read']
            },
        }],
    meta: {
        roles: ['document_in.read']
    }
}

export default router
