/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDaftarusulmusnah = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem('daftarusulmusnah');
    if(val) {
        params = val;
    } else if(lastParams) {
        lastParams = JSON.parse(lastParams);
        if(lastParams.filters) {
            params = lastParams.filters;
        }
    }

    // Params that will be recorded in localStorage
    var storedParams = params;

    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);

    axios.get("/daftarusulmusnah", {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);

        let last = {
            filters: storedParams,
        };
        localStorage.setItem("daftarusulmusnah", JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDaftarusulmusnahById = ({ commit }, val) => {
    axios.get(`/daftarusulmusnah/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getDaftarusulmusnahBerkasById = ({ commit }, val) => {
    axios.get(`/daftarusulmusnah/berkas/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getDaftarusulmusnahsuratById = ({ commit }, val) => {
    axios.get(`/daftarusulmusnahsurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getdaftarusulmusnahfoldersuratById = ({ commit }, val) => {
    axios.get(`/daftarlistsurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/daftarusulmusnah", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/daftarusulmusnah/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah detail',
        params: {
            id: val.id
        }
    })
};
export const onBerkas = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah berkas',
        params: {
            id: val.id
        }
    })
};

export const submitFolderDinilai = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/folder_dinilai/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Folder Berhasil Masuk Folder Dinilai",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'daftarusulmusnah',
            // params: {
            //     id: payload.id
            // }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const getdaftarusulmusnahListById2 = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem(`folderlist/${val.id}`);
    if (val) {
        params = val;
    } else if (lastParams) {
        lastParams = JSON.parse(lastParams);
        if (lastParams.filters) {
            params = lastParams.filters;
        }
    }
    var storedParams = params;
    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);
    axios.get(`/folderlist/${val.id}`, {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);
        let last = {
            filters: storedParams,
        };
        localStorage.setItem(`folderlist/${val.id}`, JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getdaftarusulmusnahListById = ({ commit }, val) => {
    axios.get(`/folderlist/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onDetailSurat = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah detailsurat',
        params: {
            id: val.id_surat
        }
    })
};

export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/daftarusulmusnah/${val.id}`).then(() => {
        store.dispatch('daftarusulmusnah/getDaftarusulmusnah');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitMusnahFolder = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/folder_dimusnahkan_berkas/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Folder Berhasil Dimusnahkan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'daftarusulmusnah',
            // params: {
            //     id: payload.id
            // }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onMoveBerkas = ({ commit }, payload) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftarusulmusnah moveberkas',
        params: {
            id: payload.id_surat,
            unit: payload.unit
        }
    })
};

export const submitMoveBerkas = ({ commit }, payload) => {
    axios.post("/daftarfoldermove/" + payload.id_surat, payload).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Pindah Berkas",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getdaftarusulmusnahfolderrsuratById = ({ commit }, val) => {
    axios.get(`/daftarfoldersurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitUsul = ({ commit }, val) => {
    axios.post(`/suratmusnah/${val.id}`).then(() => {
        store.dispatch('daftarusulmusnah/getDaftarusulmusnah');
        const message = {
            title: "Success",
            text: "Berhasil Memindahkan Surat ke Daftar Musnah",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/daftarusulmusnah");
    });
};


export default {
    getDaftarusulmusnah,
    getDaftarusulmusnahById,
    getDaftarusulmusnahBerkasById,
    getdaftarusulmusnahListById,
    getDaftarusulmusnahsuratById,
    getdaftarusulmusnahListById2,
    getdaftarusulmusnahfoldersuratById,
    getdaftarusulmusnahfolderrsuratById,
    onAdd,
    onEdit,
    onDetail,
    onBerkas,
    onCancel,
    onDetailSurat,
    onMoveBerkas,
    submitMoveBerkas,
    submitAdd,
    submitEdit,
    submitDelete,
    submitMusnahFolder,
    submitFolderDinilai,
    submitUsul,
};
