const router = {
    path: 'documentInSupervisor',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'documentInSupervisor add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_in_supervisor.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'documentInSupervisor edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_in_supervisor.update']
            },
        }, {
            path: 'konfirmasi/:id',
            name: 'documentInSupervisor konfirmasi',
            component: () => import('./containers/konfirmasi'),
            meta: {
                roles: ['document_in_supervisor_confirm.create']
            },
        }, {
            path: 'detail/:id',
            name: 'documentInSupervisor detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['document_in_supervisor.read']
            },
        }, {
            path: 'disposisi/:id',
            name: 'documentInSupervisor disposisi',
            component: () => import('./containers/disposisi'),
            meta: {
                roles: ['disposition_in.create']
            },
        },  {
            path: 'confirm/:id',
            name: 'documentInSupervisor confirm',
            component: () => import('./containers/confirm'),
            meta: {
                roles: ['document_in_supervisor_confirm.create']
            },
        }, {
            path: 'close/:id',
            name: 'documentInSupervisor close',
            component: () => import('./containers/close'),
            meta: {
                roles: ['document_in_supervisor_confirm.create']
            },
        }, 
        // {
        //     path: 'retur/:id',
        //     name: 'documentInSupervisor retur',
        //     component: () => import('./containers/retur'),
        //     meta: {
        //         roles: ['document_in_supervisor.read']
        //     },
        // },
         {
            path: 'return/:id',
            name: 'documentInSupervisor return',
            component: () => import('./containers/return'),
            meta: {
                roles: ['document_in_supervisor_confirm.create']
            },
        }, {
            path: '',
            name: 'documentInSupervisor',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['document_in_supervisor.read']
            },
        }],
    meta: {
        roles: ['document_in_supervisor.read']
    }
}

export default router