/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";


export const check = ({ commit }) => {
    commit(types.CHECK);
}
export const logout = ({ commit }) => {
    axios.post("/logout").then(response => {
        response = response.data;
        commit(types.LOGOUT);
        const message = {
            title: "Success",
            text: "Berhasil Logout",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'login.index',
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
}
export const login = ({ commit, state }, user) => {
    state.isLoading = true;
    axios.post("/login", {
        data: user
    }).then(response => {
        response = response.data;
        if (response.status === "success") {
            var data = response.data;
            commit(types.LOGIN, data);
            store.dispatch("profile/getProfile");
            const message = {
                title: "Success",
                text: "Berhasil Login",
                type: "success"
            };
            store.commit("addNotification", message);
            router.push({
                name: 'home.index',
            });
            state.isLoading = false;
        } else {
            state.message = response.status;
            state.isLoading = false;
        }
    })
        .catch(error => {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
            }
        });

};


export default {
    check,
    logout,
    login
};
