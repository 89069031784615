/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getFolderdimusnahkan = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem('folderdimusnahkan');
    if(val) {
        params = val;
    } else if(lastParams) {
        lastParams = JSON.parse(lastParams);
        if(lastParams.filters) {
            params = lastParams.filters;
        }
    }

    // Params that will be recorded in localStorage
    var storedParams = params;

    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);

    axios.get("/folderdimusnahkan", {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);

        let last = {
            filters: storedParams,
        };
        localStorage.setItem("folderdimusnahkan", JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getFolderdimusnahkanById = ({ commit }, val) => {
    axios.get(`/folderdimusnahkan/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getFolderdimusnahkansuratById = ({ commit }, val) => {
    axios.get(`/folderdimusnahkansurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'folderdimusnahkan add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/folderdimusnahkan", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/folderdimusnahkan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'folderdimusnahkan edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'folderdimusnahkan detail',
        params: {
            id: val.id
        }
    })
};

export const getfolderdimusnahkanListById2 = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem(`folderlist/${val.id}`);
    if (val) {
        params = val;
    } else if (lastParams) {
        lastParams = JSON.parse(lastParams);
        if (lastParams.filters) {
            params = lastParams.filters;
        }
    }
    var storedParams = params;
    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);
    axios.get(`/folderlist/${val.id}`, {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);
        let last = {
            filters: storedParams,
        };
        localStorage.setItem(`folderlist/${val.id}`, JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getfolderdimusnahkanListById = ({ commit }, val) => {
    axios.get(`/folderlist/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getfolderdimusnahkanfoldersuratById = ({ commit }, val) => {
    axios.get(`/daftarlistsurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onDetailSurat = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'folderdimusnahkan detailsurat',
        params: {
            id: val.id_surat
        }
    })
};

export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/folderdimusnahkan/${val.id}`).then(() => {
        store.dispatch('folderdimusnahkan/getFolderdimusnahkan');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onMoveBerkas = ({ commit }, payload) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'folderdimusnahkan moveberkas',
        params: {
            id: payload.id_surat,
            unit: payload.unit
        }
    })
};

export const submitMoveBerkas = ({ commit }, payload) => {
    axios.post("/daftarfoldermove/" + payload.id_surat, payload).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Pindah Berkas",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getfolderdimusnahkanfolderrsuratById = ({ commit }, val) => {
    axios.get(`/daftarfoldersurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};


export default {
    getFolderdimusnahkan,
    getFolderdimusnahkanById,
    getFolderdimusnahkansuratById,
    getfolderdimusnahkanListById,
    getfolderdimusnahkanListById2,
    getfolderdimusnahkanfoldersuratById,
    getfolderdimusnahkanfolderrsuratById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    onDetailSurat,
    onMoveBerkas,
    submitMoveBerkas,
    submitAdd,
    submitEdit,
    submitDelete
};
