const router = {
    path: 'dispositionInSupervisor',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'dispositionInSupervisor add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in_supervisor.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'dispositionInSupervisor edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in_supervisor.update']
            },
        }, {
            path: 'response',
            name: 'dispositionInSupervisor response',
            component: () => import('./containers/response'),
            meta: {
                roles: ['disposition_in_supervisor.read']
            },
        }, {
            path: 'teruskan/:dispoid',
            name: 'dispositionInSupervisor teruskan',
            component: () => import('./containers/teruskan'),
            meta: {
                roles: ['disposition_in_supervisor.create']
            },
        }, {
            path: 'jawaban/:dispoid',
            name: 'dispositionInSupervisor jawaban',
            component: () => import('./containers/jawaban'),
            meta: {
                roles: ['disposition_in_supervisor.read']
            },
        }, {
            path: 'jawabdispo/:dispoid',
            name: 'dispositionInSupervisor jawabdispo',
            component: () => import('./containers/jawabdispo'),
            meta: {
                roles: ['disposition_in_supervisor.read']
            },
        }, {
            path: 'detail/:id',
            name: 'dispositionInSupervisor detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['disposition_in_supervisor.read']
            },
        }, 
        {
            path: 'send/:id',
            name: 'dispositionInSupervisor send',
            component: () => import('./containers/send'),
            meta: {
                roles: ['disposition_in_supervisor.create']
            },
        },
        {
            path: '',
            name: 'dispositionInSupervisor',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['disposition_in_supervisor.read']
            },
        }],
    meta: {
        roles: ['disposition_in_supervisor.read']
    }
}

export default router