import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import wysiwyg from "vue-wysiwyg";
import VeeValidate from 'vee-validate'
import { i18n } from './plugins/vue-i18n';
// import './registerServiceWorker'
import 'bootstrap'
import './config'
import './mixin'
import LazyLoadDirective from "./directives/LazyLoadDirective";
import Focus from "./directives/focusInputDirective";
import Gallery from './components/uploader/gallery.vue';
import ModalDefault from './components/modal/default.vue';
import Signature from './components/modal/signature.vue';
import NewTreeView from './components/new-tree-view/index.vue';
import Datatables from 'mad-vue-datatables';
import Treeselect from '@riophae/vue-treeselect';
import { RotateSquare5 } from "vue-loading-spinner";
import CustomInput from './components/helpers/input.vue';
import CustomCheck from './components/helpers/checkbox.vue';
import CustomMulti from './components/helpers/multiselect.vue';
import DropFile from './components/helpers/dropfile.vue';
import DropFilePng from './components/helpers/dropfilepng.vue';
import DropFileDocx from './components/helpers/dropfiledocx.vue';
import CustomTree from './components/helpers/treeselect.vue';
import VueSignaturePad from 'vue-signature-pad';
import Avatar from 'vue-avatar'

Vue.use(VueSignaturePad);
var jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;
require('summernote/dist/summernote-bs4')
import summernote from './components/summernote/index.vue'

Vue.directive("lazyload", LazyLoadDirective);
Vue.directive("focus", Focus);
Vue.component('Avatar',Avatar);
Vue.component('CustomInput',CustomInput);
Vue.component('Signature',Signature);
Vue.component('DropFile',DropFile);
Vue.component('DropFilePng',DropFilePng);
Vue.component('DropFileDocx',DropFileDocx);
Vue.component('CustomMulti',CustomMulti);
Vue.component('CustomCheck',CustomCheck);
Vue.component('CustomTree',CustomTree);
Vue.component('summernote',summernote);
Vue.component('CustomLoader',RotateSquare5);
Vue.component("NewTreeView", NewTreeView);
Vue.component("Gallery", Gallery);
Vue.component("Treeselect", Treeselect);
Vue.component("Datatables", Datatables);
Vue.component("ModalDefault", ModalDefault);
import { Validator } from 'vee-validate';

Validator.extend('kemlu_password', {
    getMessage: field => `The ${field} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
    validate: value => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/.test(value)
});



Vue.use(wysiwyg, {
    // { [module]: boolean (set true to hide) }
    hideModules: { "removeFormat": true, "separator": true },

    // you can override icons too, if desired
    // just keep in mind that you may need custom styles in your application to get everything to align
    iconOverrides: {},

    // if the image option is not set, images are inserted as base64
    image: {
        uploadURL: "/api/myEndpoint",
        dropzoneOptions: {}
    },

    // limit content height if you wish. If not set, editor size will grow with content.
    maxHeight: "500px",

    // set to 'true' this will insert plain text without styling when you paste something into the editor.
    forcePlainTextOnPaste: true
});
Vue.use(VeeValidate);
Vue.config.productionTip = false
store.dispatch('auth/check');

router.beforeEach((to, from, next) => {
    if (to.name == 'login') {
        if (store.state.isLogged)
            return next('/');
        else
            return next();
    }
    if(to.name == from.name){
        next();
        window.location.reload();
        return next();
    }

    var hasRoles = to.meta.roles != undefined;
    var isPublic = hasRoles && to.meta.roles.indexOf('public') != -1;
    // console.log(store.state.auth.isLogged)
    // Non-logged user accessing non-public route.
    if (!store.state.auth.isLogged && !isPublic) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    }
    // Non-public route and has defined roles.
    else if (!isPublic && hasRoles) {
        var hasRole = false;
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        let metaTag = to.meta.roles[0];
        let perm = [];
        if (Number.isInteger(metaTag)) {
            hasRole = true;
        } else {
            perm = metaTag.split('.');
            if (!Array.isArray(perm) && perm.length != 2) {
                hasRole = true;
            } else if (permissions[perm[0]] === undefined) {
                hasRole = false;
            } else if (!hasRole && permissions[perm[0]][perm[1]]) {
                hasRole = true;
            }
        }

        if (!hasRole) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            store.dispatch('auth/logout');
            next('/login');
        }
    }
    store.dispatch("profile/getNotification");
    next();
});

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')