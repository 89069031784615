/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
import { STATE } from './mutation-types';

export default {
    [STATE](state, val) {
        state = Object.assign(state, val);
    }
};
