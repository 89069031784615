const router = {
    path: 'dokumenManual',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'dokumenManual add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['dokumenManual.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'dokumenManual edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['dokumenManual.update']
            },
        }, {
            path: 'detail/:id',
            name: 'dokumenManual detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['dokumenManual.read']
            },
        }, {
            path: '',
            name: 'dokumenManual',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['dokumenManual.read']
            },
        }],
    meta: {
        roles: ['dokumenManual.read']
    }
}

export default router