/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDaftareligiblepermanen = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem('daftareligiblepermanen');
    if(val) {
        params = val;
    } else if(lastParams) {
        lastParams = JSON.parse(lastParams);
        if(lastParams.filters) {
            params = lastParams.filters;
        }
    }

    // Params that will be recorded in localStorage
    var storedParams = params;

    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);

    axios.get("/daftareligiblepermanen", {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);

        let last = {
            filters: storedParams,
        };
        localStorage.setItem("daftareligiblepermanen", JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDaftareligiblepermanenById = ({ commit }, val) => {
    axios.get(`/daftareligiblepermanen/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getDaftareligiblepermanenBerkasById = ({ commit }, val) => {
    axios.get(`/daftareligiblepermanen/berkas/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getDaftareligiblepermanensuratById = ({ commit }, val) => {
    axios.get(`/daftareligiblepermanensurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/daftareligiblepermanen", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/daftareligiblepermanen/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen edit',
        params: {
            id: val.id
        }
    })
};
export const submitFolderDinilai = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/folder_dinilai/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Folder Berhasil Masuk Folder Dinilai",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'daftareligiblepermanen',
            // params: {
            //     id: payload.id
            // }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen detail',
        params: {
            id: val.id
        }
    })
};

export const getdaftareligiblepermanenListById2 = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem(`folderlistp/${val.id}`);
    if (val) {
        params = val;
    } else if (lastParams) {
        lastParams = JSON.parse(lastParams);
        if (lastParams.filters) {
            params = lastParams.filters;
        }
    }
    var storedParams = params;
    let defaults = {
        page: 1,
        limit: 5
    };
    params = Object.assign(defaults, params);
    axios.get(`/folderlist/${val.id}`, {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);
        let last = {
            filters: storedParams,
        };
        localStorage.setItem(`folderlist/${val.id}`, JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getdaftareligiblepermanenListById = ({ commit }, val) => {
    axios.get(`/folderlist/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const getdaftareligiblepermanenfoldersuratById = ({ commit }, val) => {
    axios.get(`/daftarlistsurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onDetailSurat = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen detailsurat',
        params: {
            id: val.id_surat
        }
    })
};

export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/daftareligiblepermanen/${val.id}`).then(() => {
        store.dispatch('daftareligiblepermanen/getDaftareligiblepermanen');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onBerkas = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen berkas',
        params: {
            id: val.id
        }
    })
};

export const submitMusnah = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/folder_dimusnahkan/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Folder Berhasil Dimusnahkan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'folderdinilai',
            // params: {
            //     id: payload.id
            // }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onMoveBerkas = ({ commit }, payload) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'daftareligiblepermanen moveberkas',
        params: {
            id: payload.id_surat,
            unit: payload.unit
        }
    })
};

export const submitMoveBerkas = ({ commit }, payload) => {
    axios.post("/daftarfoldermove/" + payload.id_surat, payload).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Pindah Berkas",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitDipermanen = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/permanenkan_folder/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Folder Berhasil Masuk Folder Dinilai",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'daftareligiblepermanen',
            // params: {
            //     id: payload.id
            // }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const getdaftareligiblepermanenfolderrsuratById = ({ commit }, val) => {
    axios.get(`/daftarfoldersurat/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitUsul = ({ commit }, val) => {
    axios.post(`/usulpermanen/${val.id}`).then(() => {
        store.dispatch('daftareligiblepermanen/getDaftareligiblepermanen');
        const message = {
            title: "Success",
            text: "Berhasil Mengusukan Surat ke Daftar Usul Permanen",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/daftareligiblepermanen");
    });
};


export default {
    getDaftareligiblepermanen,
    getDaftareligiblepermanenById,
    getDaftareligiblepermanenBerkasById,
    getDaftareligiblepermanensuratById,
    getdaftareligiblepermanenListById,
    getdaftareligiblepermanenListById2,
    getdaftareligiblepermanenfoldersuratById,
    getdaftareligiblepermanenfolderrsuratById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    onMoveBerkas,
    onBerkas,
    onDetailSurat,
    submitAdd,
    submitEdit,
    submitDelete,
    submitMusnah,
    submitFolderDinilai,
    submitMoveBerkas,
    submitDipermanen,
    submitUsul,
};
