/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDispositionSend = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('disposition_send')) {
        params = JSON.parse(last[1]);
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    let nomor_surat = store.state.dispositionSend.nomor_surat;
    let nomor_kendali = store.state.dispositionSend.nomor_kendali;
    let ringkasan = store.state.dispositionSend.ringkasan;
    axios.get("/disposition_sended", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1')),
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            nomor_surat: nomor_surat,
            nomor_kendali: nomor_kendali,
            ringkasan: ringkasan,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("document_in", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDispositionSendById = ({ commit }, val) => {
    axios.get(`/disposition_send/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/disposition_send", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/disposition_send/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend edit',
        params: {
            id: val.id
        }
    })
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend detail',
        params: {
            id: val.id
        }
    })
};

export const onDetailTL = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend detailTL',
        params: {
            id: val.id
        }
    })
};

export const onDetailTLI = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: false,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend detailTLI',
        params: {
            id: val.id
        }
    })
};

export const onLanjuti = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isLanjuti: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend lanjuti',
        params: {
            id: val.id
        }
    })
};

// export const onLanjuti = ({ commit }) => {
//     const state = {
//         isEdit: false,
//         isAdd: false,
//         isDetail: false,
//         isDisposisi: false,
//         isLanjuti: true,
//         loaded: true,
//     }
//     commit(types.STATE, state)
//     router.push({
//         name: 'dispositionSend lanjuti'
//     }).catch(err => {
//     })
// };

export const onSend = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionSend send',
        params: {
            id: val.id
            // id: val.atasan1
        }
    })
};

export const onKomentar = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isKomentar: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionIn komentar',
        params: {
            id: val.id
        }
    })
};

export const submitKomentarDispo = ({ commit }, payload) => {
    axios.post("/disposition_comment", payload).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Sukses",
            text: "Berhasil Tambah Komentar Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        // router.go(-1)
        router.push({
            name: 'dispositionSend komentar',
            params: {
                id: val.id
            }
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onCancel = () => {
    router.go(-1)
};

export const onReject = ({ commit }, val) => {
    axios.post(`/disposition_send/cancel/${val.id}`)
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil membatalkan disposisi",
                type: "success"
            };
            store.commit("addNotification", message);
            router.push({
                name: 'dispositionSend'
            })
            store.dispatch('dispositionSend/getDispositionSend');
        }).catch(error => {
            // if (error.response.status === 401) {
            //     store.commit("auth/LOGOUT");
            // }
        });
};

export const onCloseDisposisi = ({ commit }, val) => {
    axios.post(`/disposition_send/close/${val}`).then(res => {
        const message = {
            title: "Success",
            text: "Berhasil menutup disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'dispositionSend'
        })
        store.dispatch('dispositionSend/getDispositionSend');
    }).catch(error => { });
};

export const submitDelete = ({ commit }, val) => {
    axios.delete(`/disposition_send/${val.id}`).then(() => {
        store.dispatch('dispositionSend/getDispositionSend');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

// export const submitLanjuti = ({ commit }, payload) => {
//     axios.post("/lanjuti/" + payload.id, {
//         data: payload.data,
//         // _method: "PATCH"
//     }).then(response => {
//         const state = {
//             isEdit: false,
//             isAdd: false,
//             isDetail: false,
//             items: response.data.items,
//             loaded: false,
//         }
//         commit(types.STATE.state)
//         const message = {
//             title: "Success",
//             text: "Berhasil Menindaklanjuti Surat",
//             type: "success"
//         };
//         store.commit("addNotification", message);
//         router.push('/dispositionSend');
//     }).catch(error => {
//         if (error.response.status === 401) {
//             store.dispatch("auth/logout");
//         }
//     });
// };

export const submitLanjuti = ({ commit }, payload) => {
    axios.post("/lanjuti/" + payload.id, {
        data: payload.data,
        // _method: "PATCH"
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };

        store.commit("addNotification", message);
        router.push('/dispositionSend');

    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};


export default {
    getDispositionSend,
    getDispositionSendById,
    onAdd,
    onEdit,
    onDetail,
    onDetailTL,
    onDetailTLI,
    onSend,
    onCancel,
    onReject,
    onCloseDisposisi,
    onLanjuti,
    submitAdd,
    submitEdit,
    submitDelete,
    submitDisposisi,
    submitLanjuti,
    submitKomentarDispo,

};
