const router = {
    path: 'facilities',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'facilities add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['facility.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'facilities edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['facility.update']
            },
        }, {
            path: 'detail/:id',
            name: 'facilities detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['facility.read']
            },
        }, {
            path: '',
            name: 'facilities',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['facility.read']
            },
        }],
    meta: {
        roles: ['facility.read']
    }
}

export default router