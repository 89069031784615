/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDocumentInAlreadySend = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('document_in')) {
        params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 2) {
        params = {}
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    axios.get("/document_in_already", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1')),
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            approval: 2,
            from: store.state.documentInAlreadySend.from
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("surat_masuk", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDocumentInAlreadySendById = ({ commit }, val) => {
    axios.get(`/document_in/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInAlreadySend add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/document_in", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/document_in/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/documentIn");
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInAlreadySend edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInAlreadySend detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/document_in/${val.id}`).then(() => {
        store.dispatch('documentInAlreadySend/getDocumentInAlreadySend');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const approveDoc = ({ commit }, payload) => {
    axios.post(`document_in/approval`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Edit Data",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};
export const goSend = ({ commit }, payload) => {
    axios.post(`document_in/send`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Edit Data",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};


export default {
    getDocumentInAlreadySend,
    getDocumentInAlreadySendById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    approveDoc,
    goSend
};
