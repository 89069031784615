/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import axios from "axios";
import store from '@/store';
import { i18n } from '@/plugins/vue-i18n';
import {
    CHECK,
    REGISTER,
    LOGIN,
    LOGOUT,
} from './mutation-types';

export default {
    [CHECK](state) {
        state.isLogged = !!localStorage.getItem('token');
        if (state.isLogged) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            axios.defaults.headers.common['Role'] = localStorage.getItem('user_group_id')
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.defaults.headers.common['Cache-Control'] = 'no-cache'
            store.dispatch("profile/getProfile");
            store.dispatch("profile/getNotification");
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                const today = new Date();
                const expiredDate = new Date(user.expired_date*1000);
                if (expiredDate > today) {
                    i18n.locale = user ? user.lang : 'id'
                } else {
                    store.dispatch("auth/logout");
                }
    
            }
        
        }
    },

    [REGISTER]() {
        //
    },

    [LOGIN](state, data) {
        state.isLogged = true
        state.message = ''
        const user = {
            id: data.id,
            is_eselon: data.is_eselon,
            last_login: data.last_login,
            expired_date: data.expired_date,
            front_title: data.front_title,
            rear_title: data.rear_title,
            name: data.name,
            lang: data.lang,
            email: data.email,
            nik: data.nik,
            isLeader: data.isLeader,
            nip: data.nip,
            phone: data.phone,
            address: data.address,
            passphrase: data.passphrase,
            unit_id: data.unit_id,
            structural_position_id: data.structural_position_id,
            structural_eselon_id: data.structural_eselon_id,
            user_group_id: data.user_group_id,
            unit: data.user_unit,
            units: data.units,
            eselon: data.eselon
        };
        i18n.locale = user.lang
        localStorage.setItem("user_group_id", data.user_group_id);
        localStorage.setItem("token", data.api_token);
        localStorage.setItem("user", JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.api_token}`
        axios.defaults.headers.common['Role'] = data.user_group_id;
        axios.defaults.headers.common['Cache-Control'] = 'no-cache'
    },

    [LOGOUT](state) {
        state.isLogged = false
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user_group_id");
        localStorage.removeItem("token");
        localStorage.removeItem("refFacilities");
        localStorage.removeItem("refUnit");
        axios.defaults.headers.common['Authorization'] = ``
        axios.defaults.headers.common['Role'] = '';
        axios.defaults.headers.common['Cache-Control'] = 'no-cache'
    }
};
