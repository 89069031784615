const router = {
    path: 'dispositionIn',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'dispositionIn add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'dispositionIn edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in.update']
            },
        }, {
            path: 'response',
            name: 'dispositionIn response',
            component: () => import('./containers/response'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'teruskan/:dispoid',
            name: 'dispositionIn teruskan',
            component: () => import('./containers/teruskan'),
            meta: {
                roles: ['disposition_in.create']
            },
        }, {
            path: 'jawaban/:dispoid',
            name: 'dispositionIn jawaban',
            component: () => import('./containers/jawaban'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'jawabdispo/:dispoid',
            name: 'dispositionIn jawabdispo',
            component: () => import('./containers/jawabdispo'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'detail/:id',
            name: 'dispositionIn detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'detailTL/:id',
            name: 'dispositionIn detailTL',
            component: () => import('./containers/detailTL'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'detailTLI/:id',
            name: 'dispositionIn detailTLI',
            component: () => import('./containers/detailTLI'),
            meta: {
                roles: ['disposition_in.read']
            }
        }, {
            path: 'send/:id',
            name: 'dispositionIn send',
            component: () => import('./containers/send'),
            meta: {
                roles: ['disposition_in.create']
            },
        },
        {
            path: '',
            name: 'dispositionIn',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['disposition_in.read']
            },
        }],
    meta: {
        roles: ['disposition_in.read']
    }
}

export default router