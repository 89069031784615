/* eslint-disable no-unused-vars */
import Vue from "vue";
import { API_URL } from "./config";
import { keyED } from "./config";
import FineUpload from "fine-uploader-wrappers";
import CryptoJS from "crypto-js";
// Properties shared by all components.
Vue.mixin({
  data() {
    return {
      jwt: "0Ql2dqtyKvCOMJDoz9Tes39NJhGkKr5b",
      baseUrl: API_URL,
    };
  },
  methods: {
    notifSuccess(opt) {
      const message = {
        title: opt.title,
        text: opt.text,
        type: "success"
      };
      this.$store.commit("addNotification", message);
    },
    notifError(opt) {
      const message = {
        title: opt.title,
        text: opt.text,
        type: "error",
      };
      this.$store.commit("addNotification", message);
    },
    keyED() {
      return keyED;
    },
    handleApiError(error) {
      // Unauthorized. Expired token, accessing forbidden API, etc.
      if (error.status === 401) {
        this.$router.push("/logout");
      }
    },
    formatDate(input) {
      var hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      var bulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      var tanggal = new Date(input).getDate();
      var xhari = new Date(input).getDay();
      var xbulan = new Date(input).getMonth();
      var xtahun = new Date(input).getYear();

      var thari = hari[xhari];
      var tbulan = bulan[xbulan];
      var tahun = xtahun < 1000 ? xtahun + 1900 : xtahun;

      return thari + ", " + tanggal + " " + tbulan + " " + tahun;
    },
    formatDateTime(input) {
      var hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      var bulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      var time = new Date(input).toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      var tanggal = new Date(input).getDate();
      var xhari = new Date(input).getDay();
      var xbulan = new Date(input).getMonth();
      var xtahun = new Date(input).getYear();

      var thari = hari[xhari];
      var tbulan = bulan[xbulan];
      var tahun = xtahun < 1000 ? xtahun + 1900 : xtahun;

      return (
        thari + ", " + tanggal + " " + tbulan + " " + tahun + " pukul " + time
      );
    },
    fineUploader(vm, modul) {
      const uploader = new FineUpload({
        options: {
          validation: {
            sizeLimit: 20000000,
            allowedExtensions:['pdf'],
          },
          request: {
            endpoint: API_URL + "/" + modul + "/assets/upload",
            customHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Role: localStorage.getItem("user_group_id"),
            },
          },
          deleteFile: {
            enabled: true,
            endpoint: API_URL + "/" + modul + "/assets/delete",
            customHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Role: localStorage.getItem("user_group_id"),
            },
          },
          // session:{
          // endpoint:API_URL +'/'+ modul +'/assets/'+(this.$route.params.id?this.$route.params.id:'null'),
          // customHeaders:{
          // "Authorization": `Bearer ${localStorage.getItem('token')}`,
          // "Role": localStorage.getItem('user_group_id')
          // }
          // },
          callbacks: {
            onError(id, name, error, xhr) {
              const message = {
                title: "Error",
                text: error,
                type: "success",
              };
              vm.$store.commit("addNotification", message);
            },
            onComplete(id, name, data, xhr) {
              vm.files.push({
                id: id,
                uuid: data.uuid,
                filename: data.file,
                folder: data.folder,
                name: name,
              });
            },
            onSubmitDelete(id) {
              let uuid = vm.uploader.methods.getUuid(id);
              vm.files = vm.files.filter((x) => {
                return x.uuid != uuid;
              });
            },
            onStatusChange(id, old, newS) {
              if (old == null && newS == "upload successful") {
                let uuid = vm.uploader.methods.getUuid(id);
                let ind = vm.files
                  .map((x) => {
                    return x.uuid;
                  })
                  .indexOf(uuid);
                vm.files[ind].id = id;
              }
              if (newS == "canceled") {
                vm.files = vm.files.filter((x) => {
                  return x.id != id;
                });
              }
            },
            onTotalProgress: function(sent, total) {
              var progressPercent = (sent / total).toFixed(2);
              if (isNaN(progressPercent)) {
                document.getElementById("progress-text").innerHTML = "";
                document
                  .getElementById("progress-wrap-mad")
                  .setAttribute("hidden", true);
              } else {
                var progress = (progressPercent * 100).toFixed() + "%";
                document.getElementById("progress-text").innerHTML = progress;
                document.getElementById("progress-text").style.width = progress;
                if (progress == "100%") {
                  document
                    .getElementById("progress-wrap-mad")
                    .setAttribute("hidden", true);
                } else {
                  document
                    .getElementById("progress-wrap-mad")
                    .removeAttribute("hidden");
                }
              }
            },
          },
        },
      });
      return uploader;
    },
    fineUploaderPdf(vm, modul) {
      const uploader = new FineUpload({
        options: {
          validation: {
            sizeLimit: 20000000,
            allowedExtensions:['pdf'],
          },
          request: {
            endpoint: API_URL + "/" + modul + "/assets/upload",
            customHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Role: localStorage.getItem("user_group_id"),
            },
          },
          deleteFile: {
            enabled: true,
            endpoint: API_URL + "/" + modul + "/assets/delete",
            customHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Role: localStorage.getItem("user_group_id"),
            },
          },
          // session:{
          // endpoint:API_URL +'/'+ modul +'/assets/'+(this.$route.params.id?this.$route.params.id:'null'),
          // customHeaders:{
          // "Authorization": `Bearer ${localStorage.getItem('token')}`,
          // "Role": localStorage.getItem('user_group_id')
          // }
          // },
          callbacks: {
            onError(id, name, error, xhr) {
              const message = {
                title: "Error",
                text: error,
                type: "success",
              };
              vm.$store.commit("addNotification", message);
            },
            onComplete(id, name, data, xhr) {
              vm.files.push({
                id: id,
                uuid: data.uuid,
                filename: data.file,
                folder: data.folder,
                name: name,
              });
            },
            onSubmitDelete(id) {
              let uuid = vm.uploader.methods.getUuid(id);
              vm.files = vm.files.filter((x) => {
                return x.uuid != uuid;
              });
            },
            onStatusChange(id, old, newS) {
              if (old == null && newS == "upload successful") {
                let uuid = vm.uploader.methods.getUuid(id);
                let ind = vm.files
                  .map((x) => {
                    return x.uuid;
                  })
                  .indexOf(uuid);
                vm.files[ind].id = id;
              }
              if (newS == "canceled") {
                vm.files = vm.files.filter((x) => {
                  return x.id != id;
                });
              }
            },
            onTotalProgress: function(sent, total) {
              var progressPercent = (sent / total).toFixed(2);
              if (isNaN(progressPercent)) {
                document.getElementById("progress-text").innerHTML = "";
                document
                  .getElementById("progress-wrap-mad")
                  .setAttribute("hidden", true);
              } else {
                var progress = (progressPercent * 100).toFixed() + "%";
                document.getElementById("progress-text").innerHTML = progress;
                document.getElementById("progress-text").style.width = progress;
                if (progress == "100%") {
                  document
                    .getElementById("progress-wrap-mad")
                    .setAttribute("hidden", true);
                } else {
                  document
                    .getElementById("progress-wrap-mad")
                    .removeAttribute("hidden");
                }
              }
            },
          },
        },
      });
      return uploader;
    },

    encryptMethodLength() {
      var encryptMethod = this.encryptMethod();
      var aesNumber = encryptMethod.match(/\d+/)[0];
      return parseInt(aesNumber);
    },

    encryptKeySize() {
      var aesNumber = this.encryptMethodLength();
      return parseInt(aesNumber / 8);
    },

    encryptMethod() {
      return "AES-256-CBC";
    },

    decrypt(encryptedString, key) {
      var json = JSON.parse(
        CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
      );

      var salt = CryptoJS.enc.Hex.parse(json.salt);
      var iv = CryptoJS.enc.Hex.parse(json.iv);

      var encrypted = json.ciphertext; // no need to base64 decode.

      var iterations = parseInt(json.iterations);
      if (iterations <= 0) {
        iterations = 999;
      }
      var encryptMethodLength = this.encryptMethodLength() / 4; // example: AES number is 256 / 4 = 64
      var hashKey = CryptoJS.PBKDF2(key, salt, {
        hasher: CryptoJS.algo.SHA512,
        keySize: encryptMethodLength / 8,
        iterations: iterations,
      });

      var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
        mode: CryptoJS.mode.CBC,
        iv: iv,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    },

    encrypt(string, key) {
      var iv = CryptoJS.lib.WordArray.random(16);

      var salt = CryptoJS.lib.WordArray.random(256);
      var iterations = 999;
      var encryptMethodLength = this.encryptMethodLength() / 4; // example: AES number is 256 / 4 = 64
      var hashKey = CryptoJS.PBKDF2(key, salt, {
        hasher: CryptoJS.algo.SHA512,
        keySize: encryptMethodLength / 8,
        iterations: iterations,
      });

      var encrypted = CryptoJS.AES.encrypt(string, hashKey, {
        mode: CryptoJS.mode.CBC,
        iv: iv,
      });
      var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

      var output = {
        ciphertext: encryptedString,
        iv: CryptoJS.enc.Hex.stringify(iv),
        salt: CryptoJS.enc.Hex.stringify(salt),
        iterations: iterations,
      };

      return CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(JSON.stringify(output))
      );
    },
    laterThan(date1, date2) {
      let lessDate = new Date(date1);
      let moreDate = new Date(date2);
      // console.log(lessDate <= moreDate)
      return lessDate <= moreDate;
    },
    validateDate(date) {
      var newDate = new Date();
      var presentDate = newDate.getDate();
      var presentMonth = newDate.getMonth();
      var presentYear = newDate.getFullYear();
      var pastDateVal = date;
      if (pastDateVal == null) return false;
      var validatePattern = /^(\d{4})(-)(\d{1,2})(-)(\d{1,2})$/;
      let dateValues = pastDateVal.match(validatePattern);
      if (dateValues == null) {
        return false;
      }
      var pastYear = dateValues[1];
      let pastMonth = dateValues[3];
      let pastDate = dateValues[5];
      if (pastMonth < 1 || pastMonth > 12) {
        return false;
      } else if (pastDate < 1 || pastDate > 31) {
        return false;
      } else if (
        (pastMonth == 4 ||
          pastMonth == 6 ||
          pastMonth == 9 ||
          pastMonth == 11) &&
        pastDate == 31
      ) {
        return false;
      } else if (pastMonth == 2) {
        var isleap =
          pastYear % 4 == 0 && (pastYear % 100 != 0 || pastYear % 400 == 0);
        if (pastDate > 29 || (pastDate == 29 && !isleap)) {
          return false;
        }
      } else if (pastYear > presentYear || pastYear + 70 < presentYear) {
        return false;
      } else if (pastYear == presentYear) {
        if (pastMonth > presentMonth + 1) {
          return false;
        } else if (pastMonth == presentMonth + 1) {
          if (pastDate > presentDate) {
            return false;
          }
        }
      }
      return true;
    },
  },
});
