/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '../../router';
import store from '../../store';
import * as types from './mutation-types';
import axios from "axios";

export const getDisposisi = ({ commit }) => {

    axios.get("/dispositions").then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi add'
    })
};
export const onEdit = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: false,
        isDetail: false,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi detail',
        params: {
            id: val.id
        }
    })
};

export const onCancel = () => {
    router.go(-1)
};
export const onDelete = ({ commit }, val) => {
    axios.delete(`/dispositions/${val.id}`).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
        // store.dispatch('disposisi/getDisposisi')
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};


export default {
    getDisposisi,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    onDelete
};
