import Vue from 'vue'
import Vuex from 'vuex'

import approval from './views/approval/store';
import suratFisik from './views/suratFisik/store';

// Drafting
import drafting from './views/drafting/store';
import drafting_file from './views/drafting-file/store';
import drafting_file_retracted from './views/drafting-file-retracted/store';
import drafting_file_approved from './views/drafting-file-approved/store';
import drafting_file_declined from './views/drafting-file-declined/store';
import drafting_approved from './views/drafting-approved/store';
import drafting_declined from './views/drafting-declined/store';
import drafting_retracted from './views/drafting-retracted/store';
// End Drafting

// START SURAT MASUK

import documentIn from './views/documentIn/store';
import documentInSupervisor from './views/documentInSupervisor/store';
import documentInSended from './views/documentInSended/store';
import documentInAlreadySend from './views/documentInAlreadySend/store';
import documentInRejected from './views/documentInRejected/store';

// END SURAT MASUK

import docPergub from './views/docPergub/store';
import manualBook from './views/manualBook/store';

// START DISPOSISI

import dispositionIn from './views/dispositionIn/store';
import dispositionInSupervisor from './views/dispositionInSupervisor/store';
import dispositionSend from './views/dispositionSend/store';
import dispositionBack from './views/dispositionBack/store';
import dispositionAlreadySend from './views/dispositionAlreadySend/store';

// END DISPOSISI

// START LAPORAN

import laporanDokumenIn from './views/report/laporanDokumenIn/store';
import laporanDispositionIn from './views/report/laporanDispositionIn/store';
import laporanDokumenOut from './views/report/laporanDokumentKeluar/store';
import laporanDispositionSended from './views/report/laporanDispositionSended/store';
import laporanSMT from './views/report/laporanSMT/store';
// END LAPORAN

// START LAPORAN GRAFIK

import laporanDokumenInGrafik from './views/report/laporanDokumenInGrafik/store';
import laporanDispositionInGrafik from './views/report/laporanDispositionInGrafik/store';
import laporanDokumenOutGrafik from './views/report/laporanDokumentKeluarGrafik/store';

// END LAPORAN GRAFIK

// START STATISTIK

// import statistikJenisSurat from './views/statistic/statistikJenisSurat6/store';
// import statistikSifatSurat from './views/statistic/statistikSifatSurat4/store';
// import statistikTujuanSurat from './views/statistic/statistikTujuanSurat4/store';
import statistikJumlahSurat from './views/statistic/statistikJumlahSurat/store';
import statistikPencarianLanjut from './views/statistic/statistikPencarianLanjut/store';

// END STATISTIK

// START ARSIP

// import pemberkasan from './views/pemberkasan/store';
import pemberkasan from './views/arsiparis/pemberkasan/store';
import daftarfolder from './views/arsiparis/daftarfolder/store';
import folderdinilai from './views/arsiparis/folderdinilai/store';

// END ARSIP

// START ARSIP MUSNAH

import daftareligiblemusnah from './views/arsiparis/daftareligiblemusnah/store';
import daftarusulmusnah from './views/arsiparis/daftarusulmusnah/store';
import folderdimusnahkan from './views/arsiparis/folderdimusnahkan/store';

// END ARSIP MUSNAH

// START ARSIP PERMANEN

import daftareligiblepermanen from './views/arsiparis/daftareligiblepermanen/store';
import daftarusulpermanen from './views/arsiparis/daftarusulpermanen/store';
import folderdipermanenkan from './views/arsiparis/folderdipermanenkan/store';

// END ARSIP PERMANEN

import documentRetracted from './views/documentRetracted/store';
import documentRejected from './views/documentRejected/store';
import documentAlreadySend from './views/documentAlreadySend/store';
import suratKeluar from './views/suratKeluar/store';
import suratKeluarTerkirim from './views/suratKeluarTerkirim/store';
import tracking from './views/tracking/store';
import suratMemo from './views/suratMemo/store';
import pencarianDokumen from './views/pencarianDokumen/store';
import agenda from './views/agenda/store';
import meeting from './views/meeting/store';
import meetingPesan from './views/meetingPesan/store';
import rekapPesan from './views/rekapPesan/store';
import suratTracking from './views/suratTracking/store';
import disposisi from './views/disposisi/store';
import profile from './views/profile/store';
import groups from './views/references/groups/store';
import user from './views/references/user/store';
import unitGroup from './views/references/unitGroup/store';
import unit from './views/references/refUnit/store';
import rooms from './views/references/refRuangan/store';
import facilities from './views/references/refFasilitas_old/store';
import jabatan from './views/references/jabatan/store';
import jenisSurat from './views/references/refJenisSurat/store';
import instruksiDisposisi from './views/references/refInstruksiDisposisi/store';
import templateDokumen from './views/references/refTemplateDokumen/store';
// import fasilitasRuangan from './views/references/fasilitasRuangan/store';
import clasification from './views/references/refKlasifikasiSurat/store';
import clasificationdoc from './views/references/refKlasifikasiDokumen/store';
import information from './views/references/refInformation/store';
import fasilitas from './views/references/refFasilitas/store';
import typeSurat from './views/references/typeSurat/store';
import templateSurat from './views/references/templateSurat/store';
import dokumenPergub from './views/references/dokumenPergub/store';
import dokumenManual from './views/references/dokumenManual/store';
import kodeKlasifikasi from './views/references/kodeKlasifikasi/store';
import jenisArsip from './views/references/jenisArsip/store';
import asalSurat from './views/references/asalSurat/store';
import kodeSurat from './views/references/kodeSurat/store';
import faQ from './views/references/faQ/store';
// import retensiArsip from './views/references/retensiArsip/store';
import faqUser from './views/faqUser/store';
import layananInformasi from './views/references/layananInformasi/store';
// import askedQuestions from './views/references/askedQuestions/store';
// import manualBook from './views/references/manualBook/store';
// import asalFaq from './views/references/asalFaq/store';
import retensiArsip from './views/references/retensiArsip/store';
import auth from './views/login/store';
import umum from './views/umum/store';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        drafting,
        drafting_file,
        drafting_file_retracted,
        drafting_file_approved,
        drafting_file_declined,
        drafting_approved,
        drafting_declined,
        drafting_retracted,
        approval,
        suratFisik,
        documentIn,
        documentInSended,
        documentInAlreadySend,
        documentInRejected,
        docPergub,
        manualBook,
        dispositionIn,
        dispositionSend,
        dispositionBack,
        dispositionAlreadySend,
        laporanDokumenIn,
        laporanDispositionIn,
        laporanDokumenInGrafik,
        laporanDispositionInGrafik,
        laporanDokumenOutGrafik,
        // statistikJenisSurat,
        // statistikSifatSurat,
        // statistikTujuanSurat,
        statistikJumlahSurat,
        statistikPencarianLanjut,
        documentRetracted,
        documentRejected,
        documentAlreadySend,
        suratKeluar,
        suratKeluarTerkirim,
        suratMemo,
        pencarianDokumen,
        agenda,
        meeting,
        meetingPesan,
        rekapPesan,
        clasification,
        clasificationdoc,
        information,
        fasilitas,
        tracking,
        suratTracking,
        auth,
        disposisi,
        profile,
        groups,
        user,
        unitGroup,
        unit,
        rooms,
        facilities,
        jabatan,
        jenisSurat,
        instruksiDisposisi,
        templateDokumen,
        // fasilitasRuangan,
        typeSurat,
        templateSurat,
        dokumenPergub,
        dokumenManual,
        kodeKlasifikasi,
        jenisArsip,
        asalSurat,
        kodeSurat,
        faQ,
        faqUser,
        // retensiArsip,
        layananInformasi,
        // askedQuestions,
        // manualBook,
        // asalFaq,
        retensiArsip,
        laporanDokumenOut,
        laporanDispositionSended,
        laporanSMT,
        pemberkasan,
        daftarfolder,
        folderdinilai,
        daftareligiblemusnah,
        daftarusulmusnah,
        folderdimusnahkan,
        daftareligiblepermanen,
        daftarusulpermanen,
        folderdipermanenkan,
        documentInSupervisor,
        dispositionInSupervisor,
        // descriptionMail
    },
    state: {
        isMobile: false,
        notification: [],
        headerNotif: [],
        notifCount: 0
    },
    mutations: {
        mobileLoaded(state) {
            state.isMobile = true
        },
        addHeaderNotification(val, item) {
            this.state.headerNotif.push(item)
        },
        setNotifCount(state, val) {
            this.state.notifCount = val;
        },
        addNotification(val, item) {
            this.state.notification.push(item)
        },
        // eslint-disable-next-line no-unused-vars
        resetNotification(state) {
            this.state.headerNotif = [];
        },
        setBadges(state, value) {
            this.state.profile.badges = value;
        },
        removeNotification(notification) {
            this.state.notification.splice(notification)
        },
    }
})
