/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getUser = ({ commit }, val) => {
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('users')) {
        params = JSON.parse(last[1]);
    }
    let page = (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1'));
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    let cari = store.state.user.cari;
    let cari2 = store.state.user.cari2;
    axios.get("/users", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: page,
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            cari: cari,
            cari2: cari2,

        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'user add'
    }).catch(err => { })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/users", payload).then(response => {
        const state = {
            items: response.data
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        store.dispatch("profile/getProfile");
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getUserById = ({ commit }, val) => {
    axios.get(`/users/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        isEdit: true,
        loaded: false
    }
    commit(types.STATE, state)
    router.push({
        name: 'user edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'user detail',
        params: {
            id: val.id
        }
    })
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/users/" + payload.id, payload.data).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        store.dispatch("profile/getProfile");
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onCancel = () => {
    router.go(-1)
};

export const submitDelete = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.delete(`/users/${val.id}`).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go()
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitUser = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post(`/user/update_user`).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

// export const submitDelete = ({ commit }, val) => {
//     axios.delete(`/users/${val.id}`).then(() => {
//         store.dispatch('user/getUser');
//         const message = {
//             title: "Success",
//             text: "Berhasil Hapus Data",
//             type: "success"
//         };
//         store.commit("addNotification", message);
//     }).catch(error => {
//         if (error.response.status === 401) {
//             store.commit("auth/LOGOUT");
//         }
//     });
// };


export default {
    getUser,
    getUserById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    submitUser,
};
