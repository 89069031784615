import axios from 'axios'
import router from './router';
import store from './store';
// export const API_URL = 'http://localhost:7000/';
export const API_URL = 'https://demo-newplo2.egov.co.id/api/';
// export const API_URL = 'http://192.168.1.8:7000/';
// export const API_URL = 'http://192.168.0.100:8000/';
// export const API_URL = 'http://192.168.1.6:8000/';
// export const API_URL = 'http://192.168.0.101:7000/';
// export const API_URL = 'https://paperless.jogjaprov.go.id:8000/api/';
// export const API_URL = 'https://sisminkada.jogjaprov.go.id/api/';
export const keyED = 'kemlu_paperless';

const jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;
import 'datatables.net-bs4'
import 'datatables.net-responsive-bs4'
window.$.ajaxSetup({
    beforeSend: function(xhr, options) {
        options.url = API_URL +'/'+ options.url;
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        // xhr.setHeader('Access-Control-Allow-Origin', 'http://localhost:8080');
        // xhr.setHeader('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
        // xhr.setHeader('Access-Control-Allow-Headers', 'Content-Type, Authorization');
        // xhr.setHeader('Access-Control-Allow-Credentials', 'true');
    }
})

axios.defaults.baseURL = API_URL;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(config.params!=undefined && config.params.limit !=undefined){
        localStorage.setItem('last_request',config.url.concat('?').concat(JSON.stringify(config.params)));
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
axios.interceptors.response.use(function(response){
    return response;
},function(error){
    if (error.response.status === 401) {
        if(store.state.notification.length){
            for(var notif in store.state.notification){
                if(store.state.notification[notif].title.contains("Log Out")){
                    return Promise.reject(error);
                }
            }
        }
        const message = {
            title: "Log Out",
            text: "Session habis atau pengguna lain sedang menggunakan user ini, silahkan login kembali",
            type: "error"
        };
        store.commit("auth/LOGOUT");
        store.commit("addNotification", message);
        router.push({
            name: 'login.index',
        });
    } 
    return Promise.reject(error);
});