/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getPemberkasan = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem('pemberkasan');
    if (val) {
        params = val;
    } else if (lastParams) {
        lastParams = JSON.parse(lastParams);
        if (lastParams.filters) {
            params = lastParams.filters;
        }
    }

    // Params that will be recorded in localStorage
    var storedParams = params;

    let defaults = {
        page: 1,
        limit: 100
    };
    params = Object.assign(defaults, params);

    console.log(params);

    axios.get("/pemberkasan", {
        params: params
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state);

        let last = {
            filters: storedParams,
        };
        localStorage.setItem("pemberkasan", JSON.stringify(last));
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getPemberkasanById = ({ commit }, val) => {
    axios.get(`/pemberkasan/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/pemberkasan", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'pemberkasan'
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/pemberkasan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'pemberkasan'
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/pemberkasan/${val.id}`).then(() => {
        store.dispatch('pemberkasan/getPemberkasan');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onPemberkasan = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isPemberkasan: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan pemberkasan'
    }).catch(err => { })
};

export const submitPemberkasan = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/mail_pemberkasan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Update Pemberkasan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'pemberkasan detail',
            params: {
                id: payload.id
            }
        }).catch(err => { })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch('auth/logout');
        }
    });
};

// export const onBerkas = ({ commit }) => {
//     const state = {
//         isEdit: false,
//         isAdd: false,
//         isBerkas: true,
//         isDetail: false,
//         loaded: true,
//     }
//     commit(types.STATE, state)
//     router.push({
//         name: 'pemberkasan berkas'
//     })
// };

// export const onBerkaskan = ({ commit }) => {
//     const state = {
//         isEdit: false,
//         isAdd: false,
//         isBerkas: true,
//         isDetail: false,
//         loaded: true,
//     }
//     commit(types.STATE, state)
//     router.push({
//         name: 'pemberkasan berkas'
//     })
// };

export const onBerkaskan = ({ commit }, payload) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isBerkas: true,
        isDetail: false,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan berkas',
        params: {
            id: payload.id,
            id_pivot: payload.dispo,
            unit: payload.unit
        }
    })
};

export const onBerkas = ({ commit }, payload) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isBerkas: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'pemberkasan berkas',
        params: {
            unit: payload.unit
        }
    })
};

export const submitBerkas = ({ commit }, payload) => {
    axios.post("/surat_diberkaskan", payload).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/pemberkasan').catch(err => {
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};


export default {
    getPemberkasan,
    getPemberkasanById,
    onAdd,
    onPemberkasan,
    onEdit,
    onDetail,
    onCancel,
    onBerkas,
    onBerkaskan,
    submitAdd,
    submitPemberkasan,
    submitEdit,
    submitDelete,
    submitBerkas
};
