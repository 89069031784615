const router = {
    path: 'drafting-declined',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'drafting-declined add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['drafting.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'drafting-declined edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['drafting.update']
            },
        }, {
            path: 'detail/:id',
            name: 'drafting-declined detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['drafting.read']
            },
        }, {
            path: '',
            name: 'drafting-declined',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['drafting.read']
            },
        }],
    meta: {
        roles: ['drafting.read']
    }
}

export default router