const router = {
    path: 'dispositionBack',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'dispositionBack add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'dispositionBack edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['disposition_in.update']
            },
        }, {
            path: 'detail/:id',
            name: 'dispositionBack detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'detailTL/:id',
            name: 'dispositionBack detailTL',
            component: () => import('./containers/detailTL'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'detailTLI/:id',
            name: 'dispositionBack detailTLI',
            component: () => import('./containers/detailTLI'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'lanjuti/:id',
            name: 'dispositionBack lanjuti',
            component: () => import('./containers/lanjuti'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'komentar/:id',
            name: 'dispositionBack komentar',
            component: () => import('./containers/komentar'),
            meta: {
                roles: ['disposition_in.read']
            },
        }, {
            path: 'send/:id',
            name: 'dispositionBack send',
            component: () => import('./containers/send'),
            meta: {
                roles: ['disposition_in.update']
            },
        },
        {
            path: '',
            name: 'dispositionBack',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['disposition_in.read']
            },
        }],
    meta: {
        roles: ['disposition_in.read']
    }
}

export default router