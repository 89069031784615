/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDocumentInSupervisor = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ?last.split('?'):',?,'.split('?');
    let params = {};
    if(last[0].includes('document_in_supervisor')){
        params = JSON.parse(last[1]);
    }
    if(params.approval!=undefined){
        params = {}
    }
    if(val===undefined){
        val = {}
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit !== undefined?params.limit:'15'));
    let flag_atasan = '1';
    axios.get("/document_in_supervisor", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page !== undefined?params.page:'1')),
            limit: limit,
            flag_atasan: flag_atasan,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            from: store.state.documentInSupervisor.from
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });

};
export const getDocumentInSupervisorById = ({ commit }, val) => {
    axios.get(`/document_in_supervisor/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if(error.response.status === 422){
            const message = {
                title: "Error",
                text: "Mohon Dicek Ulang Form Input",
                type: "error"
            };
            store.commit("addNotification", message);
        }
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor add'
    }).catch(err=>{
    })
};
export const submitAdd = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in_supervisor", {
        data: payload
    }).then(response => {
        const state = {
            loaded:true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if(error.response.status === 422){
            const message = {
                title: "Error",
                text: "Mohon Dicek Ulang Form Input",
                type: "error"
            };
            store.commit("addNotification", message);
        }
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const onDisposisi = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor disposisi',
        params: { id: val.id }
    }).catch(err=>{
    })
};
export const submitDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: true,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend').catch(err=>{
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const sendDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend').catch(err=>{
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const saveDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionAlreadySend').catch(err=>{
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onConfirm = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isDisposisi: false,
        isConfirm: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor confirm'
    }).catch(err=>{
    })
};

export const submitConfirm = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/mailin_konfirmasi/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Konfirmasi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'documentInSupervisor detail',
            params: {
                id: payload.id
            }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onClose = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isClose: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor close'
    }).catch(err=>{
    })
};

export const submitClose = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/mailin_close/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Surat Selesai Diproses",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'documentInSupervisor detail',
            params: {
                id: payload.id
            }
        }).catch(err=>{
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onReturn = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isRetur: false,
        isReturn: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor return'
    }).catch(err=>{
    })
};

export const submitReturn = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/return_mailin/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Retur Surat",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if(error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitEdit = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in_supervisor/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor edit',
        params: {
            id: val.id
        }
    }).catch(err=>{
    })
};

export const onKonfirmasi = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor konfirmasi',
        params: {
            id: val.id
        }
    }).catch(err=>{
    })
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSupervisor detail',
        params: {
            id: val.id
        }
    }).catch(err=>{
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.delete(`/document_in_supervisor/${val.id}`).then(() => {
        store.dispatch('documentInSupervisor/getDocumentInSupervisor');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const approveDoc = ({ commit }, payload) => {
    axios.post(`document_in_supervisor/approval`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Approve",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
            }
        });
};
export const goSend = ({ commit }, payload) => {
    axios.post(`document_in_supervisor/send`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Kirim Surat",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
            }
        });
};


export default {
    getDocumentInSupervisor,
    getDocumentInSupervisorById,
    onAdd,
    onDisposisi,
    onConfirm,
    onEdit,
    onDetail,
    onCancel,
    onClose,
    onKonfirmasi,
    // onRetur,
    onReturn,
    submitAdd,
    submitEdit,
    submitDelete,
    submitDisposisi,
    sendDisposisi,
    saveDisposisi,
    submitConfirm,
    submitClose,
    // submitRetur,
    submitReturn,
    approveDoc,
    goSend
};
