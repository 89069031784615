import { render, staticRenderFns } from "./dropfilepng.vue?vue&type=template&id=3cbd03c6&"
import script from "./dropfilepng.vue?vue&type=script&lang=js&"
export * from "./dropfilepng.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "dropfilepng.vue"
export default component.exports