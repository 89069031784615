<template id="notifications">
  <div class="notifications">
    <notification v-for="(data, index) in notifications" :key="index"
        :notification="data"
        @close-notification="removeNotification">
    </notification>
  </div>
</template>
<script>
import Notification from './Notification'
  export default{
    components: {
      notification: Notification
    },
    data () {
      return {
        notifications: this.$store.state.notification
      }
    },
    methods: {
      removeNotification(notification) {
        this.$store.commit('removeNotification', notification);
      }
    }
  }
</script>
