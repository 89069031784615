/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";
import { i18n } from '../../plugins/vue-i18n';


export const getDocumentRejected = ({ commit }, val) => {
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('document_out')) {
        params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 3) {
        params = {}
    }
    let page = (val.page !== undefined ? val.page : (params.page !== undefined ? params.page : '1'));
    let limit = (val.limit !== undefined ? val.limit : (params.limit !== undefined ? params.limit : '15'));
    let search = val.search !== undefined ? val.search : '';
    if (search != '') {
        page = '1';
    }
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    axios.get("/document_out_rejected", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: page,
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            approval: 3,
            from: store.state.documentRejected.from,
            start_date: store.state.documentRejected.start_date,
            end_date: store.state.documentRejected.end_date,
            perihal: store.state.documentRejected.perihal,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDocumentRejectedById = ({ commit }, val) => {
    axios.get(`/document_out/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentRejected add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        axios.post("/document_out", {
            data: payload
        }).then(response => {
            const state = {
                items: response.data.items
            }
            commit(types.STATE, state)
            const message = {
                title: i18n.t('success'),
                text: i18n.t('add_success'),
                type: "success"
            };
            store.commit("addNotification", message);
            resolve(response);
        }).catch(error => {
            if (error.response.status === 422) {
                const message = {
                    title: i18n.t('error'),
                    text: i18n.t('fill_form_first'),
                    type: "error"
                };
                store.commit("addNotification", message);
                window.scrollTo(0, 0);
            }
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
            reject(error);
        });
    });
};
export const submitEdit = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        let dat = JSON.parse(payload.data);
        let titleNotif = i18n.t('edit_success');
        if (dat.approval == 1) {
            titleNotif = i18n.t('send_success');
        }
        axios.post("/document_out/" + payload.id, {
            data: payload.data,
            _method: "PATCH"
        }).then((data) => {
            const message = {
                title: i18n.t('success'),
                text: titleNotif,
                type: "success"
            };
            store.commit("addNotification", message);
            resolve(data);
        }).catch(error => {
            if (error.response.status === 422) {
                const message = {
                    title: i18n.t('error'),
                    text: i18n.t('file_required'),
                    type: "error"
                };
                store.commit("addNotification", message);
            }
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
            reject(error);
        });
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentRejected edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentRejected detail',
        params: {
            id: val.id
        }
    })
};
export const onRetract = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: false,
        isDetail: false,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentRejected retract',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/document_out/${val.id}`).then(() => {
        store.dispatch('documentRejected/getDocumentRejected');
        const message = {
            title: i18n.t('success'),
            text: i18n.t('delete_success'),
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const approveDoc = ({ commit }, payload) => {

    axios.post(`document_out/approval`, { data: payload })
        .then(res => {
            let data = JSON.parse(payload);
            let message;
            if (data.approval == 1) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('approve_success'),
                    type: "success"
                };
            } else if (data.approval == 3) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('decline_success'),
                    type: "success"
                };
            } else if (data.approval == 4) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('revision_success'),
                    type: "success"
                };
            } else if (data.approval == 5) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('retract_success'),
                    type: "success"
                };
            }
            store.commit("addNotification", message);
            router.push({
                name: 'documentRejected'
            });
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};


export default {
    getDocumentRejected,
    getDocumentRejectedById,
    onAdd,
    onEdit,
    onDetail,
    onRetract,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    approveDoc
};
