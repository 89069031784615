<template>
  <div :class="[actualSize, { 'has-danger': hasDanger }]">
    <label>
      {{ label }}
      <small v-if="required" style="color:red">*</small>
    </label>
    <div>
      <input
        :placeholder="actualPlaceholder"
        :disabled="disabled"
        v-model="vmodel"
        :type="type"
        class="form-control"
      />
      <small v-if="hasDanger" class="form-control-feedback"
        >Data ini harus di isi.</small
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
      default: "12",
    },
    placeholder: {
      required: false,
      type: String,
      default: "",
    },
    type: {
      required: false,
      type: String,
      default: "text",
    },
    model: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actualSize() {
      return `col-md-${this.size}`;
    },
    actualPlaceholder() {
      return this.placeholder == null || this.placeholder == ""
        ? this.label
        : this.placeholder;
    },
    hasDanger() {
      return (
        (this.vmodel == null || this.vmodel == "") &&
        this.vmodel !== 0 &&
        this.required == true
      );
    },
  },
  data() {
    return {
      vmodel: "",
    };
  },
  mounted() {
    this.vmodel = this.model;
  },
  watch: {
    model: function(newValue) {
      this.vmodel = newValue;
    },
    vmodel: function(newValue) {
      this.$emit("data", newValue);
    },
  },
};
</script>
