<template>
  <div :class="[actualSize, { 'has-danger': hasDanger }]">
    <label :class="['control-label']">
      {{ label }} &nbsp;
      <small v-if="required" style="color:red">*</small>
    </label>
    <div :class="[{ invalid: invalid && required }]">
      <Multiselect
        :disabled="disabled"
        v-model="vmodel"
        :label="data.text"
        :track-by="data.value"
        :placeholder="actualPlaceholder"
        open-direction="bottom"
        :options="list"
        :multiple="multiple"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="true"
        :close-on-select="false"
        :options-limit="300"
        :max-height="600"
        :show-no-results="true"
        :hide-selected="true"
        @open="query('')"
        @input="emitData"
        @search-change="queryChange"
      >
        <span slot="noResult">Data tidak ditemukan</span>
      </Multiselect>
      <label class="typo__label form__label" v-if="required" v-show="invalid"
        >Data ini harus di isi.</label
      >
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    label: {
      required: true,
      type: String,
    },
    model: {
      required: true,
    },
    list: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
    size: {
      required: false,
      type: String,
      default: "12",
    },
    query: {
      required: false,
      type: Function,
      default: () => {},
    },
    placeholder: {
      required: false,
      type: String,
      default: "",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    data: {
      required: false,
      type: Object,
      default: () => {
        return { text: "text", value: "value" };
      },
    },
  },
  data() {
    return {
      isLoading: false,
      vmodel: "",
    };
  },
  computed: {
    invalid() {
      if (Array.isArray(this.vmodel)) {
        return this.vmodel.length == 0;
      } else {
        return this.vmodel == null || this.vmodel == "" || this.vmodel == {};
      }
    },
    actualSize() {
      return `col-md-${this.size}`;
    },
    actualPlaceholder() {
      return this.placeholder == null || this.placeholder == ""
        ? this.label
        : this.placeholder;
    },
    hasDanger() {
      return (
        (this.vmodel == null || this.vmodel == "") && this.required == true
      );
    },
  },
  watch: {
    list: function() {
      this.isLoading = false;
    },
    watch: {
      vmodel: {
        deep: true,
        handler(newValue) {
          this.$emit("data", newValue);
        },
      },
      model: {
        deep: true,
        handler(newValue) {
          this.vmodel = newValue;
        },
      },
    },
  },
  methods: {
    emitData(data) {
      this.$emit("data", data);
    },
    opened() {
      this.isLoading = true;
      this.onOpen();
    },
    queryChange(query) {
      this.query(query);
    },
  },
  mounted() {
    this.vmodel = this.model;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
