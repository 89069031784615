const router = {
    path: 'suratKeluar',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'suratKeluar add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_out.create']
            },
        },
        {
            path: 'retract/:id',
            name: 'suratKeluar retract',
            component: () => import('./containers/retract'),
            meta: {
                roles: ['document_out.update']
            },
        }, {
            path: 'edit/:id',
            name: 'suratKeluar edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_out.update']
            },
        }, {
            path: 'detail/:id',
            name: 'suratKeluar detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['document_out.read']
            },
        }, {
            path: '',
            name: 'suratKeluar',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['document_out.read']
            },
        }],
    meta: {
        roles: ['document_out.read']
    }
}

export default router
