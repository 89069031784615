const router = {
    path: 'daftarfolder',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'daftarfolder add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftarfolder.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'daftarfolder edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftarfolder.update']
            },
        }, {
            path: 'detail/:id',
            name: 'daftarfolder detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['daftarfolder.read']
            },
        }, {
            path: 'detailsurat/:id',
            name: 'daftarfolder detailsurat',
            component: () => import('./containers/detailsurat'),
            meta: {
                roles: ['daftarfoldersurat.read']
            },
        }, {
            path: 'berkas/:id',
            name: 'daftarfolder berkas',
            component: () => import('./containers/berkas'),
            meta: {
                roles: ['daftarfolder.create']
            },
        }, {
            path: 'moveberkas/:id',
            name: 'daftarfolder moveberkas',
            component: () => import('./containers/moveberkas'),
            meta: {
                roles: ['daftarfolder.create']
            },
        }, {
            path: '',
            name: 'daftarfolder',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['daftarfolder.read']
            },
        }],
    meta: {
        roles: ['daftarfolder.read']
    }
}

export default router