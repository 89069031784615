import Vue from 'vue'
import Router from 'vue-router'

import disposisi from './views/disposisi/router';
import approval from './views/approval/router';
import suratFisik from './views/suratFisik/router';

// Drafting
import drafting from './views/drafting/router';
import drafting_file from './views/drafting-file/router';
import drafting_file_retracted from './views/drafting-file-retracted/router';
import drafting_file_approved from './views/drafting-file-approved/router';
import drafting_file_declined from './views/drafting-file-declined/router';
import drafting_approved from './views/drafting-approved/router';
import drafting_declined from './views/drafting-declined/router';
import drafting_retracted from './views/drafting-retracted/router';
// End Drafting

// START SURAT MASUK
import documentIn from './views/documentIn/router';
import documentInSupervisor from './views/documentInSupervisor/router';
import documentInSended from './views/documentInSended/router';
import documentInAlreadySend from './views/documentInAlreadySend/router';
import documentInRejected from './views/documentInRejected/router';

// END SURAT MASUK

import docPergub from './views/docPergub/router';
import manualBook from './views/manualBook/router';

// START DISPOSISI
import dispositionIn from './views/dispositionIn/router';
import dispositionInSupervisor from './views/dispositionInSupervisor/router';
import dispositionSend from './views/dispositionSend/router';
import dispositionBack from './views/dispositionBack/router';
import dispositionAlreadySend from './views/dispositionAlreadySend/router';

// END DISPOSISI

// START LAPORAN

import laporanDokumenIn from './views/report/laporanDokumenIn/router';
import laporanDispositionIn from './views/report/laporanDispositionIn/router';
import laporanDokumentMasuk from './views/report/laporanDokumentMasuk/router';
import laporanDokumentKeluar from './views/report/laporanDokumentKeluar/router';
import laporanDispositionSended from './views/report/laporanDispositionSended/router';
import laporanSMT from './views/report/laporanSMT/router';

// END LAPORAN

// START LAPORAN GRAFIK

import laporanDokumenInGrafik from './views/report/laporanDokumenInGrafik/router';
import laporanDispositionInGrafik from './views/report/laporanDispositionInGrafik/router';
import laporanDokumentKeluarGrafik from './views/report/laporanDokumentKeluarGrafik/router';

// END LAPORAN GRAFIK

// START STATISTIK

// import statistikJenisSurat from './views/statistic/statistikJenisSurat6/router';
// import statistikSifatSurat from './views/statistic/statistikSifatSurat4/router';
// import statistikTujuanSurat from './views/statistic/statistikTujuanSurat4/router';
import statistikJumlahSurat from './views/statistic/statistikJumlahSurat/router';
import statistikPencarianLanjut from './views/statistic/statistikPencarianLanjut/router';

// END STATISTIK

// START ARSIP

// import pemberkasan from './views/pemberkasan/router';
import pemberkasan from './views/arsiparis/pemberkasan/router';
import daftarfolder from './views/arsiparis/daftarfolder/router';
import folderdinilai from './views/arsiparis/folderdinilai/router';

// END ARSIP

// START ARSIP MUSNAH

import daftareligiblemusnah from './views/arsiparis/daftareligiblemusnah/router';
import daftarusulmusnah from './views/arsiparis/daftarusulmusnah/router';
import folderdimusnahkan from './views/arsiparis/folderdimusnahkan/router';

// END ARSIP MUSNAH

// START ARSIP PERMANEN

import daftareligiblepermanen from './views/arsiparis/daftareligiblepermanen/router';
import daftarusulpermanen from './views/arsiparis/daftarusulpermanen/router';
import folderdipermanenkan from './views/arsiparis/folderdipermanenkan/router';

// END ARSIP PERMANEN

import documentRetracted from './views/documentRetracted/router';
import documentRejected from './views/documentRejected/router';
import documentAlreadySend from './views/documentAlreadySend/router';
import suratKeluar from './views/suratKeluar/router';
import suratKeluarTerkirim from './views/suratKeluarTerkirim/router';
import tracking from './views/tracking/router';
import suratMemo from './views/suratMemo/router';
import pencarianDokumen from './views/pencarianDokumen/router';
import agenda from './views/agenda/router';
import meeting from './views/meeting/router';
import meetingPesan from './views/meetingPesan/router';
import rekapPesan from './views/rekapPesan/router';
import suratTracking from './views/suratTracking/router';
import profile from './views/profile/router';
import groups from './views/references/groups/router';
import user from './views/references/user/router';
import unitGroup from './views/references/unitGroup/router';
import unit from './views/references/refUnit/router';
import rooms from './views/references/refRuangan/router';
import facilities from './views/references/refFasilitas_old/router';
import jabatan from './views/references/jabatan/router';
import jenisSurat from './views/references/refJenisSurat/router';
import instruksiDisposisi from './views/references/refInstruksiDisposisi/router';
import templateDokumen from './views/references/refTemplateDokumen/router';

import clasification from './views/references/refKlasifikasiSurat/router';
import clasificationdoc from './views/references/refKlasifikasiDokumen/router';
import information from './views/references/refInformation/router';
import fasilitas from './views/references/refFasilitas/router';
import typeSurat from './views/references/typeSurat/router';
import templateSurat from './views/references/templateSurat/router';
import dokumenPergub from './views/references/dokumenPergub/router';
import dokumenManual from './views/references/dokumenManual/router';
import kodeKlasifikasi from './views/references/kodeKlasifikasi/router';
import jenisArsip from './views/references/jenisArsip/router';
import asalSurat from './views/references/asalSurat/router';
import kodeSurat from './views/references/kodeSurat/router';
import faQ from './views/references/faQ/router';
// import retensiArsip from './views/references/retensiArsip/router';
import faqUser from './views/faqUser/router';
import layananInformasi from './views/references/layananInformasi/router';
// import askedQuestions from './views/references/askedQuestions/router';
// import asalFaq from './views/references/asalFaq/router';
// import manualBook from './views/references/manualBook/router';
// import askedQ from './view/references/askedQ/router';
import retensiArsip from './views/references/retensiArsip/router';

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('./components/layout/index.vue'),
            children: [{
                path: '',
                name: 'home.index',
                component: () => import('./views/dashboard/index.vue'),
            }, {
                path: 'example',
                name: 'example',
                component: () => import('./views/example-tree.vue'),
            }, {
                path: 'notification-detail',
                name: 'notification detail',
                component: () => import('./views/detailNotification/index.vue'),
            }, {
                path: 'notification-detail/:id',
                name: 'notification detail-id',
                component: () => import('./views/detailNotification/detail.vue'),
            }, {
                path: 'settings',
                name: 'settings',
                component: () => import('./views/settings/index.vue'),
            },
                drafting,
                drafting_file,
                drafting_file_retracted,
                drafting_file_approved,
                drafting_file_declined,
                drafting_approved,
                drafting_declined,
                drafting_retracted,
                suratFisik,
                documentIn,
                documentInSended,
                documentInAlreadySend,
                documentInRejected,
                docPergub,
                manualBook,
                dispositionIn,
                dispositionSend,
                dispositionBack,
                dispositionAlreadySend,
                laporanDokumenIn,
                laporanDispositionIn,
                laporanDokumenInGrafik,
                laporanDispositionInGrafik,
                laporanDokumentKeluarGrafik,
                // statistikJenisSurat,
                // statistikSifatSurat,
                // statistikTujuanSurat,
                statistikJumlahSurat,
                statistikPencarianLanjut,
                suratKeluar,
                suratKeluarTerkirim,
                documentAlreadySend,
                documentRejected,
                documentRetracted,
                tracking,
                suratMemo,
                pencarianDokumen,
                agenda,
                meeting,
                meetingPesan,
                rekapPesan,
                clasification,
                clasificationdoc,
                information,
                fasilitas,
                approval,
                suratTracking,
                disposisi,
                laporanDokumentMasuk,
                laporanDokumentKeluar,
                laporanDispositionSended,
                laporanSMT,
                profile,
                groups,
                user,
                unitGroup,
                unit,
                rooms,
                facilities,
                jabatan,
                jenisSurat,
                instruksiDisposisi,
                templateDokumen,
                // fasilitasRuangan,
                typeSurat,
                templateSurat,
                dokumenPergub,
                dokumenManual,
                kodeKlasifikasi,
                jenisArsip,
                asalSurat,
                kodeSurat,
                layananInformasi,
                faQ,
                // retensiArsip,
                faqUser,
                // askedQuestions,
                // manualBook,
                // asalFaq,
                retensiArsip,
                pemberkasan,
                daftarfolder,
                folderdinilai,
                daftareligiblemusnah,
                daftarusulmusnah,
                folderdimusnahkan,
                daftareligiblepermanen,
                daftarusulpermanen,
                folderdipermanenkan,
                documentInSupervisor,
                dispositionInSupervisor,
            ]
        },
        {
            path: '/preview/:document_type/:id/:file',
            name: 'preview.index',
            component: () => import('./views/preview/index.vue'),
            meta: {
                roles: ['public']
            }
        },
        {
            path: '/login',
            name: 'login.index',
            component: () => import('./views/login/index.vue'),
            meta: {
                roles: ['public']
            }
        },
        {
            path: '/umum',
            name: 'umum.index',
            component: () => import('./views/umum/index.vue'),
            meta: {
                roles: ['public']
            }
        },
        {
            path: '*',
            name: '404',
            component: () => import('./views/404/index.vue'),
            meta: {
                roles: ['public']
            }
        }
    ]
})
