/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDocumentIn = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('document_in')) {
        params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
        params = {}
    }
    if (val === undefined) {
        val = {}
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit !== undefined ? params.limit : '15'));
    let flag_atasan = '0';
    let flag_from = store.state.documentIn.from;
    let atasan = store.state.documentIn.jabatan;
    let cari = store.state.documentIn.cari;
    let nomor_surat = store.state.documentIn.nomor_surat;
    let nomor_kendali = store.state.documentIn.nomor_kendali;
    let ringkasan = store.state.documentIn.ringkasan;
    let asal = store.state.documentIn.asal;
    let opd = store.state.documentIn.opd;
    let start_date = store.state.documentIn.start_date;
    let end_date = store.state.documentIn.end_date;
    let atasan1 = '';
    if (atasan > '0' && flag_from == 'pimpinan') {
        atasan1 = 'pimpinan';
    } else if (atasan > '0' && flag_from != 'pimpinan') {
        // atasan1 = flag_from;
        atasan1 = 'pimpinan';
    } else {
        atasan1 = flag_from;
    }
    axios.get("/document_in", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page !== undefined ? params.page : '1')),
            limit: limit,
            flag_atasan: flag_atasan,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            // jabatan: atasan1,
            from: atasan1,
            // from: atasan,
            from1: store.state.documentIn.from,
            // from2: store.state.documentIn.jabatan,
            cari: cari,
            nomor_surat: nomor_surat,
            opd: opd,
            nomor_kendali: nomor_kendali,
            ringkasan: ringkasan,
            asal: asal,
            start_date: start_date,
            end_date: end_date,

        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });

};

export const getDocumentInUmum = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('document_in')) {
        params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
        params = {}
    }
    if (val === undefined) {
        val = {}
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit !== undefined ? params.limit : '15'));
    let flag_atasan = '0';
    let flag_from = store.state.documentIn.from;
    let atasan = store.state.documentIn.jabatan;
    let cari = store.state.documentIn.cari;
    let nomor_surat = store.state.documentIn.nomor_surat;
    let nomor_kendali = store.state.documentIn.nomor_kendali;
    let ringkasan = store.state.documentIn.ringkasan;
    let asal = store.state.documentIn.asal;
    let opd = store.state.documentIn.opd;
    let start_date = store.state.documentIn.start_date;
    let end_date = store.state.documentIn.end_date;
    let atasan1 = '';
    if (atasan > '0' && flag_from == 'pimpinan') {
        atasan1 = 'pimpinan';
    } else if (atasan > '0' && flag_from != 'pimpinan') {
        // atasan1 = flag_from;
        atasan1 = 'pimpinan';
    } else {
        atasan1 = flag_from;
    }
    axios.get("/document_in_umum", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page !== undefined ? params.page : '1')),
            limit: limit,
            flag_atasan: flag_atasan,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            // jabatan: atasan1,
            from: atasan1,
            // from: atasan,
            from1: store.state.documentIn.from,
            // from2: store.state.documentIn.jabatan,
            cari: cari,
            nomor_surat: nomor_surat,
            opd: opd,
            nomor_kendali: nomor_kendali,
            ringkasan: ringkasan,
            asal: asal,
            start_date: start_date,
            end_date: end_date,

        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    })

};

export const getDocumentInById = ({ commit }, val) => {
    axios.get(`/document_in/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 422) {
            const message = {
                title: "Error",
                text: "Mohon Dicek Ulang Form Input",
                type: "error"
            };
            store.commit("addNotification", message);
        }
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn add'
    }).catch(err => {
    })
};

export const submitAdd = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in", {
        data: payload
    }).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 422) {
            const message = {
                title: "Error",
                text: "Mohon Dicek Ulang Form Input",
                type: "error"
            };
            store.commit("addNotification", message);
        }
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitLanjuti = ({ commit }, payload) => {
    axios.post("lanjutidocin/" + payload.id, {
        data: payload.data,
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Input Hasil Tindaklanjut",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/documentIn");
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onDisposisi = ({ commit }, val) => {
    // const state = {
    //     isEdit: false,
    //     isAdd: false,
    //     isDetail: false,
    //     isDisposisi: true,
    //     loaded: true,
    // }
    // commit(types.STATE, state)
    router.push({
        name: 'documentIn disposisi',
        params: { id: val.id }
    }).catch(err => {
    })
};
export const submitDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: true,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend').catch(err => {
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitTandaiAtasan = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in_atasan/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: true,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tandai Surat Atasan",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/documentIn').catch(err => {
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitTandaiAtasanDepan = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in_atasan/" + payload.id, {
        data: payload,
        _method: "PATCH"
    }).then(() => {
        store.dispatch('documentIn/getDocumentIn', payload);
        const message = {
            title: "Success",
            text: "Berhasil Tandai Surat Atasan",
            type: "success"
        };
        store.commit("addNotification", message);
        // router.go(-1)
        // router.push({
        //     name: 'documentIn'
        // })
    });
};

// export const submitTandaiAtasanDepan = ({ commit }, payload) => {
//     let current = {
//         loaded: false,
//     }
//     commit(types.STATE, current)
//     axios.post("/document_in_atasan/" + payload.id, {
//         data: payload,
//         _method: "PATCH"
//     }).then(response => {
//         const state = {
//             isEdit: false,
//             isAdd: false,
//             isDetail: false,
//             isDisposisi: false,
//             items: response.data.items,
//             loaded: true,
//         }
//         commit(types.STATE, state)
//         const message = {
//             title: "Success",
//             text: "Berhasil Tandai Surat Atasan",
//             type: "success"
//         };
//         store.commit("addNotification", message);
//         router.push('/documentIn').catch(err => { });
//     }).catch(error => {
//         if (error.response.status === 401) {
//             store.dispatch("auth/logout");
//         }
//     });
// };


export const sendDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/documentIn').catch(err => {
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const saveDisposisi = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/disposition_in", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionAlreadySend').catch(err => {
        });
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onConfirm = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isDisposisi: false,
        isConfirm: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn confirm'
    }).catch(err => {
    })
};

export const submitConfirm = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/mailin_konfirmasi/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Konfirmasi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'documentIn detail',
            params: {
                id: payload.id
            }
        }).catch(err => {
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onClose = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isClose: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn close'
    }).catch(err => {
    })
};

export const submitClose = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/mailin_close/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Surat Selesai Diproses",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'documentIn',
            // params: {
            //     id: payload.id
            // }
        }).catch(err => {
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const onReturn = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isRetur: false,
        isReturn: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn return'
    }).catch(err => {
    })
};

export const submitReturn = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/return_mailin/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Retur Surat",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitEdit = ({ commit }, payload) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post("/document_in/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/documentIn");
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn edit',
        params: {
            id: val.id
        }
    }).catch(err => {
    })
};

export const onKonfirmasi = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn konfirmasi',
        params: {
            id: val.id
        }
    }).catch(err => {
    })
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn detail',
        params: {
            id: val.id
        }
    }).catch(err => {
    })
};

export const onDetailTL = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn detailTL',
        params: {
            id: val.id
        }
    }).catch(err => {
    })
};

export const onDetailTLI = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: false,
        idDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn detailTLI',
        params: {
            id: val.id
        }
    })
};

export const onAtasan = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentIn detail',
        params: {
            id: val.id
        }
    }).catch(err => {
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.delete(`/document_in/${val.id}`).then(() => {
        store.dispatch('documentIn/getDocumentIn');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
export const approveDoc = ({ commit }, payload) => {
    axios.post(`document_in/approval`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Approve",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
            }
        });
};
export const goSend = ({ commit }, payload) => {
    axios.post(`document_in/send`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Kirim Surat",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
            }
        });
};


export default {
    getDocumentIn,
    getDocumentInUmum,
    getDocumentInById,
    onAdd,
    onDisposisi,
    onConfirm,
    onEdit,
    onDetail,
    onDetailTL,
    onDetailTLI,
    onAtasan,
    onCancel,
    onClose,
    onKonfirmasi,
    // onRetur,
    onReturn,
    submitAdd,
    submitEdit,
    submitDelete,
    submitDisposisi,
    submitTandaiAtasan,
    submitTandaiAtasanDepan,
    sendDisposisi,
    saveDisposisi,
    submitConfirm,
    submitClose,
    submitLanjuti,
    // submitRetur,
    submitReturn,
    approveDoc,
    goSend
};
