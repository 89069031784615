/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getLaporanDokumenOutGrafik = ({ commit }, val) => {
    var params = {};
    var lastParams = localStorage.getItem('laporan_surat_keluar');
    if(val) {
        params = val;
    } else if(lastParams) {
        lastParams = JSON.parse(lastParams);
        if(lastParams.filters) {
            params = lastParams.filters;
        }
    }

    let defaults = {
        page: 1,
        limit: 15
    };

    params = Object.assign(defaults, params);

    axios.get("/laporan_docout_grafik", {
        params: params,
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        let last = {
            filters: params,
        };
        localStorage.setItem("laporan_surat_keluar", JSON.stringify(last));
        // localStorage.setItem("surat_keluar", JSON.stringify(response.data.items));
        // if (val) {
        //     if (val.limit >= "100") {
        //         localStorage.setItem("surat_keluar", JSON.stringify(response.data.items));
        //     }
        // }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getLaporanDokumenOutGrafikById = ({ commit }, val) => {
    axios.get(`/laporan_docout_grafik/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'laporanDokumenOutGrafik add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/laporan_docout_grafik", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/laporan_docout_grafik/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'laporanDokumenOutGrafik edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'laporanDokumenOutGrafik detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/laporan_docout_grafik/${val.id}`).then(() => {
        store.dispatch('laporanDokumenOutGrafik/getLaporanDokumenOutGrafik');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};


export default {
    getLaporanDokumenOutGrafik,
    getLaporanDokumenOutGrafikById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete
};
