/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getRooms = ({ commit }, val) => {
    axios.get("/rooms", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: val.page !== undefined ? val.page : '1',
            limit: val.limit !== undefined ? val.limit : '15',
            sortable: val.shortable !== undefined ? val.shortable : 'asc'
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getRoomsById = ({ commit }, val) => {
    axios.get(`/rooms/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'rooms add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/rooms", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/rooms/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'rooms edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'rooms detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};

export const submitDelete = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.delete(`/rooms/${val.id}`).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go()
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

// export const submitDelete = ({ commit }, val) => {
//     axios.delete(`/rooms/${val.id}`).then(() => {
//         store.dispatch('rooms/getRooms');
//         const message = {
//             title: "Success",
//             text: "Berhasil Hapus Data",
//             type: "success"
//         };
//         store.commit("addNotification", message);
//     }).catch(error => {
//         if (error.response.status === 401) {
//             store.commit("auth/LOGOUT");
//         }
//     });
// };


export default {
    getRooms,
    getRoomsById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete
};
