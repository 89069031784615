/* eslint-disable no-unused-vars */
/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";
import { i18n } from '../../plugins/vue-i18n';

export const getdrafting = ({ commit }, val) => {
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('draftings')) {
        params = JSON.parse(last[1]);
    }
    let page = (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1'));
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    axios.get("/drafting_retracted", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: page,
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc'
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'drafting-file add'
    }).catch(err => { })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/drafting_retracted", payload).then(response => {
        const state = {
            isAdd: false,
            items: response.data
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getdraftingById = ({ commit }, val) => {
    axios.get(`/drafting_retracted/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        isEdit: true,
        loaded: false
    }
    commit(types.STATE, state)
    router.push({
        name: 'drafting_retracted edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'drafting_retracted detail',
        params: {
            id: val.id
        }
    })
};
export const onDelete = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: false
    }
    commit(types.STATE, state)
    router.push({
        name: 'drafting_retracted retract',
        params: {
            id: val.id
        }
    })
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/drafting_retracted/" + payload.id, Object.assign(payload, { _method: "PATCH" })).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        store.dispatch("profile/getProfile");
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/drafting_retracted/${val.id}`).then(() => {
        store.dispatch('drafting_retracted/getdrafting');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const approveDoc = ({ commit }, payload) => {
    axios.post(`drafting_retracted/approval`, { data: payload })
        .then(res => {
            let data = JSON.parse(payload);
            let message;
            if (data.approval == 1) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('approve_success'),
                    type: "success"
                };
            } else if (data.approval == 3) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('decline_success'),
                    type: "success"
                };
            } else if (data.approval == 4) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('revision_success'),
                    type: "success"
                };
            } else if (data.approval == 5) {
                message = {
                    title: i18n.t('success'),
                    text: i18n.t('retract_success'),
                    type: "success"
                };
            }
            store.commit("addNotification", message);
            router.push({
                name: 'drafting_retracted'
            }).catch(err => {

            });
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};

export default {
    getdrafting,
    getdraftingById,
    onAdd,
    onEdit,
    approveDoc,
    onDelete,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete
};
