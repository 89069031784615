const router = {
    path: 'daftarusulpermanen',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'daftarusulpermanen add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftarusulpermanen.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'daftarusulpermanen edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftarusulpermanen.update']
            },
        }, {
            path: 'detail/:id',
            name: 'daftarusulpermanen detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['daftarusulpermanen.read']
            },
        }, {
            path: 'detailsurat/:id',
            name: 'daftarusulpermanen detailsurat',
            component: () => import('./containers/detailsurat'),
            meta: {
                roles: ['daftarusulpermanensurat.read']
            },
        }, {
            path: 'moveberkas/:id',
            name: 'daftarusulpermanen moveberkas',
            component: () => import('./containers/moveberkas'),
            meta: {
                roles: ['daftarusulpermanen.create']
            },
        }, {
            path: 'berkas/:id',
            name: 'daftarusulpermanen berkas',
            component: () => import('./containers/berkas'),
            meta: {
                roles: ['daftarusulpermanen.read']
            },
        }, {
            path: '',
            name: 'daftarusulpermanen',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['daftarusulmusnah.read']
            },
        }],
    meta: {
        roles: ['daftarusulmusnah.read']
    }
}

export default router