export default {
    loaded: false,
    isEdit: false,
    isAdd: false,
    isDetail: false,
    isDisposisi: false,
    isConfirm: false,
    isClose: false,
    isKonfirmasi: false,
    isRetur: false,
    idReturn: false,
    items: [],
    detail:{}
};