/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '../../router'
import store from '../../store'
import * as types from './mutation-types';
import axios from "axios";

export const getProfile = ({ commit }) => {
    const user = JSON.parse(localStorage.user)
    axios.get("/users/" + user.id).then(response => {
        const userResponse = response.data
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            user: userResponse.data,
            permissions: userResponse.permissions
        }
        localStorage.setItem('permissions', JSON.stringify(userResponse.permissions));
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getNotification = ({ commit }) => {
    axios.get("/notifications").then(response => {
        store.commit('resetNotification');
        response.data.data.forEach(element => {
            store.commit('addHeaderNotification', {
                id: element.id,
                title: element.title,
                description: element.message,
                read_at: element.read_at,
                notifiable: element.notifiable,
                route: element.route,
                time: element.time_human,
                status: element.status === 1 ? true : false
            });
        });
        store.commit('setNotifCount',response.data.count);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
    axios.get("/badges").then(response => {
        store.commit('setBadges',response.data);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const getAvatar = ({ commit }, param) => {
    const user = JSON.parse(localStorage.user)
    axios.get("/users/" + user.id + '/avatar?' + param).then(response => {
        const avatar = Buffer.from(response.data).toString('base64');
        const state = {
            avatar
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi add'
    })
};
export const onEdit = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: false,
        isDetail: false,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true,
        items: []
    }
    commit(types.STATE, state)
    router.push({
        name: 'disposisi detail',
        params: {
            id: val.id
        }
    })
};

export const onCancel = () => {
    router.go(-1)
};
export const onDelete = ({ commit }, val) => {
    axios.delete(`/contacts/${val.id}`).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        commit(types.STATE, state)
        // store.dispatch('disposisi/getDisposisi')
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};


export default {
    getNotification,
    getProfile,
    getAvatar,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    onDelete
};
