<template id="notification">
  <transition name="fade">
    <div v-if="notification.type === 'success'" class="alert alert-success">
      <button
        type="button"
        @click="triggerClose(notification)"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h3 class="text-success" v-if="notification.title">
        <i class="fa fa-check-circle"></i>
        {{notification.title}}
      </h3>
      <span>{{notification.text}}</span>
    </div>
    <div v-if="notification.type === 'error'" class="alert alert-danger">
      <button
        type="button"
        @click="triggerClose(notification)"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h3 v-if="notification.title" class="text-danger">
        <i class="ni ni-fat-remove"></i>
        {{notification.title}}
      </h3>
      <span>{{notification.text}}</span>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["notification"],
  data() {
    return {
      timer: null
    };
  },
  created() {
    let timeout = this.notification.hasOwnProperty("timeout")
      ? this.notification.timeout
      : true;
    if (timeout) {
      let delay = this.notification.delay || 3000;
      this.timer = setTimeout(
        function() {
          this.triggerClose(this.notification);
        }.bind(this),
        delay
      );
    }
  },
  methods: {
    triggerClose: function(notification) {
      clearTimeout(this.timer);
      this.$store.commit("removeNotification", notification);
    }
  }
};
</script>
<style>
.notifications {
  position: fixed;
  right: 10px;
  top: 10px;
  width: 300px;
  z-index: 100;
}
.notification p {
  margin-right: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
