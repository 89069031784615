const router =
{
    path: 'laporanDokumentKeluarGrafik',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'laporanDokumentKeluarGrafik add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['laporan_docout_grafik.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'laporanDokumentKeluarGrafik edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['laporan_docout_grafik.update']
            },
        }, {
            path: 'detail/:id',
            name: 'laporanDokumentKeluarGrafik detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['laporan_docout_grafik.read']
            },
        }, {
            path: '',
            name: 'laporanDokumentKeluarGrafik',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['laporan_docout_grafik.read']
            },
    }],
    meta: {
        roles: ['laporan_docout_grafik.read']
    },
}
export default router 