/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '../../router'
import store from '../../store'
import * as types from './mutation-types';
import axios from "axios";

export const getDocumentInSended = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('document_in')) {
        params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 1) {
        params = {}
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    let cari = store.state.documentInSended.cari;
    let cari2 = store.state.documentInSended.cari2;
    let cari3 = store.state.documentInSended.cari3;
    let asal = store.state.documentInSended.asal;
    axios.get("/document_in_send", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1')),
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            approval: 1,
            cari: cari,
            cari2: cari2,
            cari3: cari3,
            asal: asal,
            from: store.state.documentInSended.from
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("document_in", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDocumentInSendedById = ({ commit }, val) => {
    axios.get(`/document_in/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSended add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/document_in", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/document_in/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSended edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSended detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};

export const onSend = ({ commit }, val) => {
    const state = {
        isEdit: true,
        isAdd: true,
        isDetail: true,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'documentInSended send',
        params: {
            id: val.id_dispo
        }
    })
};

export const onReject = ({ commit }, val) => {
    axios.post(`/disposition_send/cancel/${val.id}`)
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil membatalkan disposisi",
                type: "success"
            };
            store.commit("addNotification", message);
            router.push({
                name: 'documentInSended'
            })
            store.dispatch('documentInSended/getDocumentInSendedById');
        }).catch(error => {

        });
};

export const submitDelete = ({ commit }, val) => {
    axios.delete(`/document_in/${val.id}`).then(() => {
        store.dispatch('documentInSended/getDocumentInSended');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const approveDoc = ({ commit }, payload) => {
    axios.post(`document_in/approval`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Edit Data",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};
export const goSend = ({ commit }, payload) => {
    axios.post(`document_in/send`, { data: payload })
        .then(res => {
            const message = {
                title: "Success",
                text: "Berhasil Edit Data",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1);
        }).catch(error => {
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
        });
};


export default {
    getDocumentInSended,
    getDocumentInSendedById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    onReject,
    onSend,
    submitAdd,
    submitEdit,
    submitDelete,
    approveDoc,
    goSend
};
