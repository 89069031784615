/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */
export const STATE = 'STATE';
export const GET = 'GET';
export const CREATE = 'CREATE';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';

export default {
    STATE,
    GET,
    CREATE,
    EDIT,
    DELETE
};
