<template>
  <div id="app">
    <Notifications />
    <router-view />
  </div>
</template>

<script>
import Notifications from "./components/notification/Notifications";
export default {
  components: {
    Notifications,
  },
  mounted() {},
  created() {
    // window.addEventListener('beforeunload', this.leaving)
  },
  methods: {
    leaving() {
      this.$store.commit("auth/LOGOUT");
    },
  },
};
</script>

<style lang="scss">
@import "./assets/plugins/bootstrap/css/bootstrap.min.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";
@import "./assets/css/style.css";
@import "./assets/css/custom.css";
@import "./assets/css/colors/blue.css";
@import "~placeholder-loading/src/scss/placeholder-loading";
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import "~summernote/dist/summernote-bs4.css";

.bg-login {
  // background: url("./assets/img/bg_sipandi.png");
  // background: url("./assets/img/bg_eoffice.png");
  background: url("./assets/img/depan03.png");
  height: 120%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>



