export default {
    loaded: false,
    isEdit: false,
    isAdd: false,
    isDetail: false,
    items: [],
    detail: {},
    lang: {
        document_out: 'Surat Keluar',
        document_in: 'Surat Masuk',
        approval: 'Approval',
        facility: 'Facility',
        document_type: 'Jenis Dokumen',
        document_template: 'Dokumen Template',
        mailtemplate: 'Mail Template',
        mailtype: 'Mail Type',
        position: 'Position',
        profile: 'Profile',
        room: 'Room',
        tracking: 'Tracking',
        unit: 'Unit',
        user: 'User',
        disposition: 'Disposisi',
        groups: "User Group"
    }
};