const router = {
    path: 'kodeKlasifikasi',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'kodeKlasifikasi add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['kodeKlasifikasi.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'kodeKlasifikasi edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['kodeKlasifikasi.update']
            },
        }, {
            path: 'detail/:id',
            name: 'kodeKlasifikasi detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['kodeKlasifikasi.read']
            },
        }, {
            path: '',
            name: 'kodeKlasifikasi',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['kodeKlasifikasi.read']
            },
        }],
    meta: {
        roles: ['kodeKlasifikasi.read']
    }
}

export default router