/* eslint-disable no-unused-vars */
/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getAgenda = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    // let cari = store.state.agenda.cari;
    let start_date = store.state.agenda.start_date;
    let end_date = store.state.agenda.end_date;
    let nama_unit = store.state.agenda.nama_unit;
    let personal = store.state.agenda.personal;
    axios.get("/agenda", {
        params: {
            // search: val.search !== undefined ? val.search : '',
            // page: val.page !== undefined ? val.page : '1',
            // limit: val.limit !== undefined ? val.limit : '15',
            // sortable: val.shortable !== undefined ? val.shortable : 'asc',
            // cari: cari,
            start_date: start_date,
            end_date: end_date,
            nama_unit: nama_unit,
            personal: personal,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("agenda", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    });

};
export const getAgendaById = ({ commit }, val) => {
    axios.get(`/agenda/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    });
};

export const getAgendaTLById = ({ commit }, val) => {
    axios.get(`/agendaTL/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data,
            detail2: response.data.data2
        }
        commit(types.STATE, state)
    });
};

export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'agenda add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/agenda", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/agenda/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'agenda edit',
        params: {
            id: val.id
        }
    })
};
export const onLaporan = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'agenda laporan',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'agenda detail',
        params: {
            id: val.id
        }
    })
};

export const onDetailTL = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'agenda detailTL',
        params: {
            id: val.id
        }
    }).catch(err => {

    })
};

export const onCancel = () => {
    router.go(-1)
};

export const submitLaporan = ({ commit }, payload) => {
    axios.post("agendaLaporan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Input Laporan Agenda",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/agenda");
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

// export const submitLaporan = ({ commit }, val) => {
//     axios.post(`/agendaLaporan/${val.id}`).then(() => {
//         store.dispatch('agenda/getAgenda');
//         const message = {
//             title: "Success",
//             text: "Berhasil Melaporkan Agenda Kegiatan",
//             type: "success"
//         };
//         store.commit("addNotification", message);
//     });
// };

export const submitDelete = ({ commit }, val) => {
    axios.delete(`/agenda/${val.id}`).then(() => {
        store.dispatch('agenda/getAgenda');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    });
};




export default {
    getAgenda,
    getAgendaById,
    getAgendaTLById,
    onAdd,
    onEdit,
    onLaporan,
    onDetail,
    onDetailTL,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    submitLaporan,
};
