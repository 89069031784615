const router = {
    path: 'pemberkasan',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'pemberkasan add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['pemberkasan.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'pemberkasan edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['pemberkasan.update']
            },
        }, {
            path: 'detail/:id',
            name: 'pemberkasan detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['pemberkasan.read']
            },
        }, {
            path: 'berkas/:id',
            name: 'pemberkasan berkas',
            component: () => import('./containers/berkas'),
            meta: {
                roles: ['surat_diberkaskan.create']
            },
        }, {
            path: '',
            name: 'pemberkasan',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['pemberkasan.read']
            },
        }],
    meta: {
        roles: ['pemberkasan.read']
    }
}

export default router