/* eslint-disable no-unused-vars */
/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDocPergub = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let cari = store.state.docPergub.cari;
    let start_date = store.state.docPergub.start_date;
    let end_date = store.state.docPergub.end_date;
    axios.get("/docPergub", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: val.page !== undefined ? val.page : '1',
            limit: val.limit !== undefined ? val.limit : '15',
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            cari: cari,
            start_date: start_date,
            end_date: end_date,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("docPergub", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    });

};
export const getDocPergubById = ({ commit }, val) => {
    axios.get(`/docPergub/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'docPergub add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/docPergub", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/docPergub/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'docPergub edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'docPergub detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/docPergub/${val.id}`).then(() => {
        store.dispatch('docPergub/getDocPergub');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    });
};


export default {
    getDocPergub,
    getDocPergubById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete
};
