export default {
    loaded: false,
    isEdit: false,
    isAdd: false,
    isDetail: false,
    isResponse: false,
    isDisposisi: false,
    isJawaban: false,
    isJawabDispo: false,
    items: [],
    detail:{}
};