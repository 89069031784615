/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";
import { i18n } from '../../../plugins/vue-i18n';

export const getUnit = ({ commit }, val) => {
    let last = localStorage.getItem('last_request');
    last = last != null ? last.split('?') : ',?,'.split('?');
    let params = {};
    if (last[0].includes('units')) {
        params = JSON.parse(last[1]);
    }
    let page = (val.page !== undefined ? val.page : (params.page != undefined ? params.page : '1'));
    let limit = (val.limit !== undefined ? val.limit : (params.limit != undefined ? params.limit : '15'));
    let cari = store.state.unit.cari;
    axios.get("/units", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: page,
            limit: limit,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            cari: cari,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("refUnit", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getUnitsById = ({ commit }, val) => {
    axios.get(`/units/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'unit add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        axios.post("/units", {
            data: payload
        }).then(response => {
            const state = {
                items: response.data.items
            }
            commit(types.STATE, state)
            const message = {
                title: "Success",
                text: "Berhasil Tambah Data",
                type: "success"
            };
            store.commit("addNotification", message);
            router.go(-1)
            resolve(response);
        }).catch(error => {
            if (error.response.status === 422) {
                const message = {
                    title: i18n.t('error'),
                    text: i18n.t('fill_form_first'),
                    type: "error"
                };
                store.commit("addNotification", message);
            }
            if (error.response.status === 401) {
                store.commit("auth/LOGOUT");
            }
            reject(error)
        });
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/units/" + payload.id, payload.payload).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};
// export const submitEdit = ({ commit }, payload) => {
//     return new Promise((resolve, reject) => {
//         axios.post("/units/" + payload.id, {
//             data: payload.data,
//             _method: "PATCH"
//         }).then((response) => {
//             const message = {
//                 title: "Success",
//                 text: "Berhasil Edit Data",
//                 type: "success"
//             };
//             store.commit("addNotification", message);
//             router.go(-1)
//             resolve(response)
//         }).catch(error => {
//             if (error.response.status === 422) {
//                 const message = {
//                     title: i18n.t('error'),
//                     text: i18n.t('fill_form_first'),
//                     type: "error"
//                 };
//                 store.commit("addNotification", message);
//             }
//             if (error.response.status === 401) {
//                 store.commit("auth/LOGOUT");
//             }
//             reject(error);
//         });
//     });
// };
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'unit edit',
        params: {
            id: val.id
        }
    })
};
export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'unit detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.go(-1)
};

export const submitDelete = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.delete(`/units/${val.id}`).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go()
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitUnit = ({ commit }, val) => {
    let current = {
        loaded: false,
    }
    commit(types.STATE, current)
    axios.post(`/user/update_unit`).then(response => {
        const state = {
            loaded: true,
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

// export const submitDelete = ({ commit }, val) => {
//     return new Promise((resolve, reject) => {
//         axios.delete(`/units/${val.id}`).then(() => {
//             store.dispatch('unit/getUnit');
//             commit(types.STATE, state)
//             const message = {
//                 title: "Success",
//                 text: "Berhasil Hapus Data",
//                 type: "success"
//             };
//             store.commit("addNotification", message);
//             router.go()
//             resolve(response);
//         }).catch(error => {
//             if (error.response.status === 401) {
//                 store.commit("auth/LOGOUT");
//             }
//             reject(error)
//         });
//     });
// };


export default {
    getUnit,
    getUnitsById,
    onAdd,
    onEdit,
    onDetail,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    submitUnit,
};
