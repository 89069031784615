/* eslint-disable no-unused-vars */
/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getRekapPesan = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    // let cari = store.state.agenda.cari;
    // let start_date = store.state.agenda.start_date;
    // let end_date = store.state.agenda.end_date;
    let bulan = store.state.rekapPesan.bulan;
    let tahun = store.state.rekapPesan.tahun;
    // let kapasitas_ruangan = store.state.rekapPesan.kapasitas_ruangan;
    // let status_pemesanan = store.state.rekapPesan.status_pemesanan;
    axios.get("/rekapPesan", {
        params: {
            // search: val.search !== undefined ? val.search : '',
            // page: val.page !== undefined ? val.page : '1',
            // limit: val.limit !== undefined ? val.limit : '15',
            // sortable: val.shortable !== undefined ? val.shortable : 'asc',
            // cari: cari,
            bulan: bulan,
            tahun: tahun,
            // kapasitas_ruangan: kapasitas_ruangan,
            // status_pemesanan: status_pemesanan,
            // start_date: start_date,
            // end_date: end_date,
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("rekapPesan", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    });

};
export const getRekapPesanById = ({ commit }, val) => {
    axios.get(`/rekapPesan/${val.id}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/rekapPesan", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};

export const submitPesan = ({ commit }, payload) => {
    axios.post("pesanruangan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Pesan Ruangan",
            type: "success"
        };
        store.commit("addNotification", message);
        // router.push("/meeting");
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const submitEdit = ({ commit }, payload) => {
    axios.post("/rekapPesan/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan edit',
        params: {
            id: val.id
        }
    })
};

export const onPemesanan = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan pemesanan',
        params: {
            id: val.id
        }
    })
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan detail',
        params: {
            id: val.id
        }
    })
};

export const onApprove = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan approve',
        params: {
            id: val.id
        }
    })
};

export const onDetailTL = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'rekapPesan detailTL',
        params: {
            id: val.id
        }
    }).catch(err => {

    })
};

export const onCancel = () => {
    router.go(-1)
};

export const submitApprove = ({ commit }, val) => {
    axios.post(`/meetingApprove/${val.id}`).then(() => {
        store.dispatch('rekapPesan/getRekapPesan');
        const message = {
            title: "Success",
            text: "Berhasil Approve Meeting Room",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/rekapPesan");
    });
};

export const submitBatalPesan = ({ commit }, val) => {
    axios.post(`/meetingBatalPesan/${val.id}`).then(() => {
        store.dispatch('rekapPesan/getRekapPesan');
        const message = {
            title: "Success",
            text: "Berhasil Batal Pesan Meeting Room",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push("/rekapPesan");
    });
}

export const submitDelete = ({ commit }, val) => {
    axios.delete(`/rekapPesan/${val.id}`).then(() => {
        store.dispatch('rekapPesan/getRekapPesan');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    });
};


export default {
    getRekapPesan,
    getRekapPesanById,
    onAdd,
    onEdit,
    onPemesanan,
    onDetail,
    onDetailTL,
    onCancel,
    submitAdd,
    submitEdit,
    submitDelete,
    submitPesan,
    submitApprove,
    submitBatalPesan,
};
