const router = {
    path: 'jenisSurat',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'jenisSurat add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_type.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'jenisSurat edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['document_type.update']
            },
        }, {
            path: 'detail/:id',
            name: 'jenisSurat detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['document_type.read']
            },
        }, {
            path: '',
            name: 'jenisSurat',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['document_type.read']
            },
        }],
    meta: {
        roles: ['document_type.read']
    }
}

export default router