const router =
{
    path: 'laporanDokumentMasuk',
    component: () => import('./index.vue'),
    children: [{
        path: '',
        name: 'laporan dokument masuk',
        component: () => import('./containers/index.vue'),
        meta: {
            roles: [1]
        },
    }],
    meta: {
        roles: [1]
    },
}
export default router 