/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import router from '@/router'
import store from '@/store'
import * as types from './mutation-types';
import axios from "axios";

export const getDispositionInSupervisor = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
    }
    commit(types.STATE, state)
    let last = localStorage.getItem('last_request');
    last = last != null ?last.split('?'):',?,'.split('?');
    let params = {};
    if(last[0].includes('disposition_in_supervisor')){
        params = JSON.parse(last[1]);
    }
    let limit = (val.limit !== undefined ? val.limit : (params.limit!=undefined?params.limit:'15'));
    let flag_atasan = '1';
    axios.get("/disposition_in_supervisor", {
        params: {
            search: val.search !== undefined ? val.search : '',
            page: (val.page !== undefined ? val.page : (params.page!=undefined?params.page:'1')),
            limit: limit,
            flag_atasan: flag_atasan,
            sortable: val.shortable !== undefined ? val.shortable : 'asc',
            subject: store.state.dispositionInSupervisor.subject ? store.state.dispositionInSupervisor.subject : 'all'
        }
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            loaded: true,
            items: response.data,
            isLeader: response.data.is_leader            
        }
        if (val) {
            if (val.limit >= "100") {
                localStorage.setItem("document_in", JSON.stringify(response.data.items));
            }
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });

};
export const getDispositionInSupervisorById = ({ commit }, val) => {
    let param = null;
    if(val.id!==undefined){
        param = val.id;
    }else{
        param = val.dispoid;
    }
    axios.get(`/disposition_in_supervisor/${param}`).then(response => {
        const state = {
            loaded: true,
            detail: response.data.data
        }
        commit(types.STATE, state)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onAdd = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: true,
        isDetail: false,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor add'
    })
};
export const submitAdd = ({ commit }, payload) => {
    axios.post("/disposition_in_supervisor", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const submitEdit = ({ commit }, payload) => {
    axios.post("/disposition_in_supervisor/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(() => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};
export const onEdit = ({ commit }, val) => {
    const state = {
        loaded: false,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor edit',
        params: {
            id: val.id
        }
    })
};

export const onResponse = ({ commit }) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isResponse: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor response'
    })
};
export const submitResponse = ({ commit }, payload) => {
    axios.post("/disposition_in_supervisor", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onTeruskan = ({ commit },val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor teruskan',
        params: {
            dispoid: val.id
        }
    })
};

export const onSend = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        isDisposisi: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor send',
        params: {
            id: val.id
        }
    })
};

export const submitTeruskan = ({ commit }, payload) => {
    axios.post("/disposition_in_supervisor/forward", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push({
            name: 'dispositionInSupervisor'
        })
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onJawaban = ({ commit },val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isJawaban: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor jawaban',
        params: {
            dispoid: val.id
        }
    })
};

export const submitJawaban = ({ commit }, payload) => {
    axios.post("/disposition_response", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if(error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onJawabDispo = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        isJawabDispo: true,
        loaded: true,
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor jawabdispo',
        params: {
            dispoid: val.id
        }
    })
};

export const submitJawabDispo = ({ commit }, payload) => {
    axios.post("/jawabdispo_mail/" + payload.id, {
        data: payload.data,
        _method: "PATCH"
    }).then(response => {
        const message = {
            title: "Success",
            text: "Berhasil Edit Data",
            type: "success"
        };
                
        store.commit("addNotification", message);
        if(response.data.data.is_false == true){
            router.push({
                name: 'dispositionInSupervisor'
            })
        } else {
            router.push({
                name: 'dispositionInSupervisor detail',
                params: {
                    id: payload.id
                }
            })
        }
        
    }).catch(error => {
        if(error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};


export const submitDoneDispo = ({ commit }, payload) => {
    axios.post("/disposition_in_supervisor/donedispo", {
        data: payload
    }).then(response => {
        const state = {
            items: response.data.items
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Tambah Data",
            type: "success"
        };
        store.commit("addNotification", message);
        router.go(-1)
    }).catch(error => {
        if(error.response.state === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const onDetail = ({ commit }, val) => {
    const state = {
        isEdit: false,
        isAdd: false,
        isDetail: true,
        loaded: true
    }
    commit(types.STATE, state)
    router.push({
        name: 'dispositionInSupervisor detail',
        params: {
            id: val.id
        }
    })
};
export const onCancel = () => {
    router.push({
        name: 'dispositionInSupervisor'
    })
};
export const submitDelete = ({ commit }, val) => {
    axios.delete(`/disposition_in_supervisor/${val.id}`).then(() => {
        store.dispatch('dispositionInSupervisor/getDispositionInSupervisor');
        const message = {
            title: "Success",
            text: "Berhasil Hapus Data",
            type: "success"
        };
        store.commit("addNotification", message);
    }).catch(error => {
        if (error.response.status === 401) {
            store.commit("auth/LOGOUT");
        }
    });
};

export const submitDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const sendDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionSend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};

export const saveDisposisi = ({ commit }, payload) => {
    axios.post("/disposition_send/new_send", {
        data: payload
    }).then(response => {
        const state = {
            isEdit: false,
            isAdd: false,
            isDetail: false,
            isDisposisi: false,
            items: response.data.items,
            loaded: false,
        }
        commit(types.STATE, state)
        const message = {
            title: "Success",
            text: "Berhasil Disposisi",
            type: "success"
        };
        store.commit("addNotification", message);
        router.push('/dispositionAlreadySend');
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
        }
    });
};


export default {
    getDispositionInSupervisor,
    getDispositionInSupervisorById,
    onAdd,
    onEdit,
    onDetail,
    onResponse,
    onCancel,
    onTeruskan,
    onSend,
    onJawaban,
    onJawabDispo,
    submitAdd,
    submitEdit,
    submitDelete,
    submitResponse,
    submitTeruskan,
    submitJawaban,
    submitJawabDispo,
    submitDoneDispo,
    submitDisposisi,
    sendDisposisi,
    saveDisposisi
};
