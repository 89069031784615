<template>
  <textarea :name="name"></textarea>
</template>
<script>
/* eslint-disable no-undef */
export default {
  props: {
    model: {
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    height: {
      type: String,
      default: "250",
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    let config = {
      height: this.height,
      tabDisable: true,
      fontName: 'Arial',
      fontNames: [
        'Arial'
      ],
    };

    let vm = this;

    config.callbacks = {
      onInit: function() {
        $(vm.$el).summernote("code", vm.model);
      },

      onChange: function() {
        // $(".note-editable.card-block").keydown(function( event ) {
        //   if ( event.which == 9 ) {
        //   event.preventDefault();
        //   $(vm.$el).summernote("code",$(vm.$el).summernote("code")+'&nbsp;&nbsp;&nbsp;&nbsp;')
        //   }
        //   var msg = "Handler for .keydown() called";
        //   // eslint-disable-next-line no-console
        //   console.log(msg);
        //   // eslint-disable-next-line no-console
        //   console.log(event);
        // });
        vm.$emit("change", $(vm.$el).summernote("code"));
      },

      onBlur: function() {
        vm.$emit("change", $(vm.$el).summernote("code"));
      },
    };

    $(this.$el).summernote(config);
    $(this.$el).summernote("code", vm.model);
    $(this.$el).summernote('fontName', 'Arial');
    $(this.$el).summernote('fontNames', ['Arial']);
    if(this.disabled){
      $(this.$el).summernote("disable");
    }
    var elements = document.getElementsByClassName("modal");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    // $('.note-btn-group.btn-group.note-insert').css('display','none');
    // $('.note-btn-group.btn-group.note-table').css('display','none');
  },
  watch: {
    disabled(newValue) {
      if (newValue) {
        $(this.$el).summernote("disable");
      } else {
        $(this.$el).summernote("enable");
      }
    },
  },
};
</script>
<style>
/* ---------------------------------------------------
 SummerNote
----------------------------------------------------- */

.note-editable {
  font-family: 'Arial' !important;
}
</style>
