const router = {
    path: 'daftareligiblepermanen',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'daftareligiblepermanen add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftareligiblepermanen.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'daftareligiblepermanen edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['daftareligiblepermanen.update']
            },
        }, {
            path: 'detail/:id',
            name: 'daftareligiblepermanen detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['daftareligiblepermanen.read']
            },
        }, {
            path: 'detailsurat/:id',
            name: 'daftareligiblepermanen detailsurat',
            component: () => import('./containers/detailsurat'),
            meta: {
                roles: ['daftareligiblepermanensurat.read']
            },
        }, {
            path: 'moveberkas/:id',
            name: 'daftareligiblepermanen moveberkas',
            component: () => import('./containers/moveberkas'),
            meta: {
                roles: ['daftareligiblepermanen.create']
            },
        },{
            path: 'berkas/:id',
            name: 'daftareligiblepermanen berkas',
            component: () => import('./containers/berkas'),
            meta: {
                roles: ['daftareligiblepermanen.read']
            },
        }, {
            path: '',
            name: 'daftareligiblepermanen',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['daftareligiblepermanen.read']
            },
        }],
    meta: {
        roles: ['daftareligiblepermanen.read']
    }
}

export default router