<template>
    <div>
        <div class="progress" hidden="true" style="margin-bottom:20px;" id="progress-wrap-mad">
            <div class="progress-bar" style="width:0%" id="progress-text">0%</div>
        </div>
        <Galler :uploader="uploader"/>
    </div>
</template>
<script>
import Galler from 'vue-fineuploader/gallery';
export default {
    components:{
        Galler
    },
    props:{
        uploader:{
            required:true
        }
    }
}
</script>
