<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel1">{{ title }}</h4>
            <button
              type="button"
              @click="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
              <span v-html="content" style="color:black;"></span>
          </div>
          <div class="modal-footer" v-if="confirmation">
            <button type="button" class="btn btn-danger" :disabled="isDisabled" @click="newAction">Ya</button>
            <button type="button" class="btn btn-default" v-if="!information" data-dismiss="modal" @click="close(false)">Tidak</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "default",
  data() {
    return {
      isDisabled:false
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    newAction(){
      let _this = this
      this.isDisabled = true;
      this.action();
      setTimeout(function(){
        _this.isDisabled = false
      },2000)
    },
    close(val) {
      this.$emit("close",val);
    }
  },
  props:{
      content:{
          required:true,
          type:String
      },
      title:{
          required:true,
          type:String
      },
      confirmation:{
        required:false,
        type:Boolean,
        default:false
      },
      action:{
        type:Function,
        required:false
      },
      information:{
        required:false,
        type:Boolean,
        default:false
      }
  }
};
</script>
<style>
.modal-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  position: relative !important;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>