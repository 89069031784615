const router =
{
    path: 'laporanDokumentKeluar',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'laporanDokumentKeluar add',
            component: () => import('./containers/form'),
            meta: {
                roles: ['laporan_docout.create']
            },
        },
        {
            path: 'edit/:id',
            name: 'laporanDokumentKeluar edit',
            component: () => import('./containers/form'),
            meta: {
                roles: ['laporan_docout.update']
            },
        }, {
            path: 'detail/:id',
            name: 'laporanDokumentKeluar detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: ['laporan_docout.read']
            },
        }, {
            path: '',
            name: 'laporanDokumentKeluar',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: ['laporan_docout.read']
            },
    }],
    meta: {
        roles: ['laporan_docout.read']
    },
}
export default router 