export default {
    loaded: true,
    isEdit: false,
    isAdd: false,
    isDetail: false,
    items: [],
    detail:{},
    lang: {
        suratkeluar: 'Surat Keluar',
        suratmasuk: 'Surat Masuk',
        approval: 'Approval',
        facility: 'Facility',
        jenisdokumen: 'Jenis Dokumen',
        mailtemplate: 'Mail Template',
        mailtype: 'Mail Type',
        position: 'Position',
        profile: 'Profile',
        room: 'Room',
        tracking: 'Tracking',
        unit: 'Unit',
        user: 'User',
    }
};