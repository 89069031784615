const router = {
    path: 'agenda',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'agenda add',
            component: () => import('./containers/form'),
            meta: {
                roles: [1]
            },
        },
        {
            path: 'edit/:id',
            name: 'agenda edit',
            component: () => import('./containers/form'),
            meta: {
                roles: [1]
            },
        },
        {
            path: 'laporan/:id',
            name: 'agenda laporan',
            component: () => import('./containers/laporan'),
            meta: {
                roles: [1]
            },
        }, {
            path: 'detail/:id',
            name: 'agenda detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: [1]
            },
        }, {
            path: 'detailTL/:id',
            name: 'agenda detailTL',
            component: () => import('./containers/detailTL'),
            meta: {
                roles: [1]
            },
        }, {
            path: '',
            name: 'agenda',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: [1]
            },
        }],
    meta: {
        roles: [1]
    }
}

export default router