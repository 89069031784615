const router = {
    path: 'rekapPesan',
    component: () => import('./index.vue'),
    children: [
        {
            path: 'add',
            name: 'rekapPesan add',
            component: () => import('./containers/form'),
            meta: {
                roles: [1]
            },
        },
        {
            path: 'edit/:id',
            name: 'rekapPesan edit',
            component: () => import('./containers/form'),
            meta: {
                roles: [1]
            },
        }, {
            path: 'detail/:id',
            name: 'rekapPesan detail',
            component: () => import('./containers/detail'),
            meta: {
                roles: [1]
            },
        }, {
            path: '',
            name: 'rekapPesan',
            component: () => import('./containers/index.vue'),
            meta: {
                roles: [1]
            },
        }],
    meta: {
        roles: [1]
    }
}

export default router